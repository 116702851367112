import { PrefectureStr } from 'utils/types';
import { FC } from 'react';

const prefsvg = {
  三重県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 251.81 479.26">
      <defs>
        <style>
          .przm1{'{'}fill:#9f68e8;{'}'}.przm2{'{'}fill:#c26655;{'}'}.przm3{'{'}
          fill:#c9c21e;{'}'}.przm4{'{'}fill:#d94550;{'}'}.przm5{'{'}
          fill:#53822b;{'}'}.przm6{'{'}fill:#51b1c9;{'}'}
        </style>
      </defs>
      <path
        className="przm2"
        d="M153.14,7.87l-24.1,110.3c-1.93,8.84,7.93,15.49,15.4,10.4l84.35-57.47c5.4-3.68,5.88-11.47,.96-15.78L169.5,2.49c-5.73-5.02-14.74-2.06-16.36,5.38Z"
      />
      <path
        className="przm1"
        d="M179.74,113.07l-35.16,23.25c-5.76,3.81-6.02,12.17-.51,16.33l20,15.08c5.28,3.98,12.89,1.84,15.32-4.31l15.16-38.33c3.6-9.1-6.66-17.41-14.81-12.02Z"
      />
      <circle className="przm4" cx="25.75" cy="453.5" r="25.75" />
      <path
        className="przm3"
        d="M67.97,117.27l-9.19,87.21c-1.2,4.41,1.53,8.92,6,9.93l177.16,41.6c8.42,1.9,13.54-8.95,6.69-14.2L80.66,113.01c-4.55-3.49-11.19-1.25-12.69,4.27Z"
      />
      <path
        className="przm6"
        d="M67.7,255.28l172.97,14.2c8.24,.68,10.21,11.86,2.69,15.3l-172.97,79.39c-5.32,2.44-11.38-1.45-11.38-7.3v-93.59c0-4.7,4.01-8.39,8.69-8Z"
      />
      <path
        className="przm3"
        d="M89.74,234.96l-1.91,8.01c-.81,3.4,1.58,6.73,5.06,7.05l120.03,11.09c3.44,.32,4.2-4.71,.82-5.42l-117.37-24.92c-2.98-.63-5.92,1.23-6.63,4.19Z"
      />
      <path
        className="przm5"
        d="M84,366.38l-51.36,40.65c-6.62,5.24-4.17,15.81,4.07,17.61l12.75,2.79c4.32,.94,8.75-1.06,10.89-4.94l28.64-51.88c1.76-3.19-2.13-6.48-4.99-4.22Z"
      />
    </svg>
  ),
  京都府: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 402.28 437.42">
      <defs>
        <style>
          .przm1{'{'}fill:#c9c21e;{'}'}.przm2{'{'}fill:#d94550;{'}'}.przm3{'{'}
          fill:#d6a671;{'}'}.przm4{'{'}fill:#53822b;{'}'}.przm5{'{'}
          fill:#76b2b4;{'}'}
        </style>
      </defs>
      <path
        className="przm5"
        d="M227.03,141.52l-34.22-50.52c-3.58-5.29-11.13-5.91-15.53-1.28l-54.89,57.76c-3.77,6.9,1.53,15.25,9.38,14.78l87.48-5.3c7.65-.46,11.96-9.01,7.78-15.43Z"
      />
      <path
        className="przm3"
        d="M112.17,.39L7.27,30.23c-7.01,1.99-9.6,10.57-4.87,16.11l83.73,98.17c5.52,6.47,16.09,3.53,17.47-4.86L124.78,11.64c1.2-7.24-5.55-13.26-12.6-11.25Z"
      />
      <path
        className="przm4"
        d="M262.2,176.09l-155.61,6.22c-8.48,.34-12.71,10.42-7.01,16.71l189.84,209.55c6.68,7.37,18.85,1.54,17.29-8.28l-34.23-215.77c-.79-5.01-5.21-8.63-10.28-8.43Z"
      />
      <path
        className="przm1"
        d="M317.38,369.92l7.04,58.69c.65,5.44,5.56,9.34,11,8.75l57.91-6.26c8.85-.96,12.13-12.13,5.21-17.72l-64.95-52.43c-6.98-5.64-17.28,.06-16.21,8.97Z"
      />
      <circle className="przm2" cx="53.01" cy="168.34" r="26.16" />
    </svg>
  ),
  佐賀県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384.31 342.47">
      <defs>
        <style>
          .przm1{'{'}fill:#c26655;{'}'}.przm2{'{'}fill:#c9c21e;{'}'}.przm3{'{'}
          fill:#d94550;{'}'}.przm4{'{'}fill:#51b1c9;{'}'}
        </style>
      </defs>
      <path
        className="przm3"
        d="M176.32,20.8c-51.42-1.64-93.44,40.37-91.8,91.79,1.48,46.56,39.4,84.49,85.97,85.98,51.42,1.65,93.45-40.38,91.81-91.79-1.49-46.56-39.41-84.49-85.97-85.98Z"
      />
      <path
        className="przm4"
        d="M55.46,8.16l35.63,190.67c1.27,6.81-4.59,12.82-11.43,11.71l-71.26-11.56c-5.59-.91-9.31-6.27-8.21-11.82L35.82,8.05c2.15-10.8,17.62-10.71,19.64,.11Z"
      />
      <path
        className="przm1"
        d="M78.45,230.74l112.37-23.37c6.84-1.42,10.44,7.79,4.45,11.39l-95.18,57.11c-4.89,2.93-11.23,1.19-13.94-3.82l-14.46-26.75c-3.18-5.89,.21-13.18,6.76-14.55Z"
      />
      <path
        className="przm1"
        d="M165.49,251.71l-50.71,30.94c-6.53,3.99-6.35,13.54,.33,17.27l73.91,41.25c7.93,4.42,17.17-3.15,14.39-11.79l-23.2-72.19c-1.98-6.16-9.2-8.85-14.73-5.48Z"
      />
      <path
        className="przm2"
        d="M264.01,38.99l11.13,149.71c.67,9.07,12.12,12.6,17.79,5.5l88-110.26c4.19-5.25,2.12-13.05-4.12-15.53l-99.13-39.45c-6.86-2.73-14.22,2.67-13.67,10.03Z"
      />
      <path
        className="przm2"
        d="M355.36,50.32l23.49,9.14c3.16,1.23,6.32-1.78,5.24-4.99l-5.87-17.62c-.76-2.29-3.36-3.38-5.53-2.34l-17.62,8.48c-3.15,1.52-2.97,6.06,.29,7.33Z"
      />
    </svg>
  ),
  兵庫県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 319.63 485.09">
      <defs>
        <style>
          .przm1{'{'}fill:#e8a21c;{'}'}.przm2{'{'}fill:#9f68e8;{'}'}.przm3{'{'}
          fill:#8fb505;{'}'}.przm4{'{'}fill:#c26655;{'}'}.przm5{'{'}
          fill:#d94550;{'}'}.przm6{'{'}fill:#53822b;{'}'}.przm7{'{'}
          fill:#51b1c9;{'}'}
        </style>
      </defs>
      <circle className="przm5" cx="228.82" cy="248.49" r="90.81" />
      <path
        className="przm1"
        d="M176.06,450.17l4.83,12.09c1.95,4.88-.2,10.43-4.93,12.72l-18.73,9.06c-9.14,4.42-18.29-6.01-12.71-14.49l13.9-21.15c4.44-6.75,14.64-5.73,17.64,1.78Z"
      />
      <path
        className="przm7"
        d="M133.59,474.72l62.09-87.06c2.21-3.1-1.92-6.76-4.73-4.18l-76.75,70.41c-3.47,3.19-4.25,8.36-1.87,12.43l4.49,7.65c3.67,6.25,12.56,6.65,16.77,.75Z"
      />
      <path
        className="przm3"
        d="M204.14,54.34l-7.31,5.05c-6.13,4.24-5.63,13.46,.92,17.01l7.31,3.96c6.66,3.61,14.77-1.21,14.77-8.79v-9.01c0-8.07-9.06-12.81-15.69-8.22Z"
      />
      <path
        className="przm3"
        d="M49.87,1.32L159.45,0c9.37-.11,13.74,11.57,6.58,17.63L87.84,83.83c-4.99,4.22-12.62,2.61-15.46-3.28L40.99,15.67c-3.19-6.59,1.56-14.27,8.88-14.35Z"
      />
      <path
        className="przm6"
        d="M166.44,31.9L80.89,99.9c-6.65,5.29-4.09,15.94,4.24,17.63l91.13,18.44c6.45,1.31,12.39-3.87,11.96-10.45l-5.58-86.44c-.52-8.06-9.88-12.21-16.2-7.18Z"
      />
      <path
        className="przm2"
        d="M62.34,154.85l61.61,127.28c3.24,6.69-1.7,14.45-9.13,14.36l-104.94-1.35c-6.81-.09-11.54-6.81-9.33-13.25l43.33-125.93c2.9-8.44,14.57-9.14,18.46-1.1Z"
      />
      <path
        className="przm4"
        d="M81.08,130.21l91.23,12.16c8.45,1.13,11.71,11.64,5.37,17.35l-60.82,54.74c-5.02,4.52-13,2.86-15.79-3.29l-30.41-66.91c-3.25-7.15,2.64-15.09,10.43-14.05Z"
      />
    </svg>
  ),
  北海道: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 413.92 413.56">
      <defs>
        <style>
          .przm1{'{'}fill:#ffb554;{'}'}.przm2{'{'}fill:#8fb505;{'}'}.przm3{'{'}
          fill:#d94550;{'}'}.przm4{'{'}fill:#53822b;{'}'}.przm5{'{'}
          fill:#76b2b4;{'}'}
        </style>
      </defs>
      <path
        className="przm5"
        d="M103.21,241.74L133.35,8.73c1.01-7.81,10.22-11.44,16.29-6.42l249.31,206.13c6.86,5.67,3.45,16.81-5.41,17.66l-279.45,26.89c-6.39,.61-11.7-4.87-10.88-11.24Z"
      />
      <path
        className="przm1"
        d="M311.6,248.82l-178.98,17.53c-9.51,.93-12.42,13.4-4.3,18.45l117.62,73.05c4.56,2.83,10.54,1.56,13.56-2.89l61.36-90.59c4.75-7.01-.82-16.39-9.25-15.56Z"
      />
      <path
        className="przm4"
        d="M8.59,404.82L.09,338.65c-1.05-8.2,7.74-14.07,14.91-9.94l64.35,37.03c6.84,3.94,6.64,13.88-.36,17.53l-55.85,29.14c-6.17,3.22-13.66-.69-14.54-7.59Z"
      />
      <ellipse
        className="przm3"
        cx="51.86"
        cy="278.13"
        rx="49.7"
        ry="49.77"
        transform="translate(-181.48 118.13) rotate(-45)"
      />
      <path
        className="przm2"
        d="M397.84,122.09l-30.34,23.23c-5.06,3.88-5.25,11.44-.39,15.56l25.13,21.34c6.09,5.17,15.48,1.47,16.4-6.46l5.22-44.57c1.03-8.77-9-14.47-16.01-9.1Z"
      />
    </svg>
  ),
  千葉県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 387.94 483.28">
      <defs>
        <style>
          .przm1{'{'}fill:#8fb505;{'}'}.przm2{'{'}fill:#c26655;{'}'}.przm3{'{'}
          fill:#d94550;{'}'}.przm4{'{'}fill:#395298;{'}'}
        </style>
      </defs>
      <path
        className="przm1"
        d="M284.1,151.75L3.88,13.6C-3.56,9.68,.6-1.63,8.8,.2L380.09,83.14c9.8,2.01,10.66,15.66,1.2,18.9l-88.2,51.1c-3.05,1.04-6.41,.52-9-1.39Z"
      />
      <path
        className="przm2"
        d="M22.15,135.62l53.72-62.06c3.08-3.55,8.21-4.49,12.34-2.24l191.7,90.13c7.65,6.04,2.77,18.33-6.94,17.47L31.1,145.39c-5.07-.44-8.95-4.69-8.95-9.77Z"
      />
      <path
        className="przm4"
        d="M176.44,400.18l-71.5-44.56c-6.53-4.22-15.14,.46-15.14,8.24l4.73,112.31c2.04,7.14,10.97,9.51,16.28,4.33l65.08-63.51c6.45-3.61,6.75-12.78,.54-16.8Z"
      />
      <circle className="przm3" cx="189.45" cy="278.38" r="102.86" />
      <path
        className="przm1"
        d="M319.16,59.32l-126.88-24.62c-6.6-1.28-5.82-10.95,.89-11.17l82.01-2.62c1.93-.06,3.84,.45,5.49,1.47l42.6,26.44c5.41,3.36,2.13,11.7-4.11,10.49Z"
      />
    </svg>
  ),
  和歌山県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 370.51 453.69">
      <defs>
        <style>
          .przm1{'{'}fill:#e8a21c;{'}'}.przm2{'{'}fill:#8fb505;{'}'}.przm3{'{'}
          fill:#d94550;{'}'}
        </style>
      </defs>
      <circle className="przm3" cx="247.21" cy="346.08" r="107.62" />
      <path
        className="przm1"
        d="M21.8,45.12L233.7,.22c7.81-1.66,14.08,6.44,10.53,13.59l-62.23,125.25c-2.4,4.83-8.32,6.71-13.07,4.16L19.27,62.87c-7.84-4.21-6.17-15.91,2.54-17.75Z"
      />
      <path
        className="przm1"
        d="M39.55,102.74L.45,227.86c-2.23,7.15,4.26,13.97,11.51,12.09l180.66-46.92c8.35-2.17,9.77-13.43,2.22-17.6L53.28,97.23c-5.29-2.92-11.93-.26-13.73,5.51Z"
      />
      <path
        className="przm2"
        d="M41.67,247.65l146.09-38.82c9.25-2.46,15.96,8.7,9.46,15.72l-79.75,86.1c-3.26,3.52-8.64,4.07-12.55,1.29l-66.34-47.28c-6.58-4.69-4.72-14.93,3.09-17Z"
      />
      <path
        className="przm1"
        d="M337.86,209.74l26.62-15.53c3.35-1.96,7.25,1.53,5.67,5.08l-8.87,19.97c-.79,1.78-2.74,2.73-4.63,2.26l-17.75-4.44c-3.48-.87-4.14-5.53-1.05-7.34Z"
      />
    </svg>
  ),
  埼玉県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412.68 233.93">
      <defs>
        <style>
          .przm1{'{'}fill:#9f68e8;{'}'}.przm2{'{'}fill:#8fb505;{'}'}.przm3{'{'}
          fill:#c26655;{'}'}.przm4{'{'}fill:#d94550;{'}'}
        </style>
      </defs>
      <path
        className="przm3"
        d="M159.55,7.25l-45.02,157.29c-1.77,6.17,2.65,12.38,9.06,12.74l278.51,15.39c9.91,.55,14.48-12.12,6.5-18.02L175.11,1.97c-5.61-4.15-13.64-1.42-15.56,5.29Z"
      />
      <circle className="przm4" cx="55.37" cy="136.78" r="55.37" />
      <path
        className="przm2"
        d="M241.06,32.99l134.45,97.42c8.15,5.91,18.94-2.57,15.12-11.89L345.17,7.46c-1.75-4.28-6.2-6.8-10.77-6.1l-88.98,13.64c-8.84,1.35-11.59,12.74-4.35,17.98Z"
      />
      <path
        className="przm1"
        d="M224.76,194.47l128.93,8.12c8.18,.51,9.54,11.99,1.7,14.4l-53.6,16.49c-2.03,.62-4.21,.59-6.21-.11l-73.51-25.46c-7.61-2.64-5.34-13.95,2.7-13.44Z"
      />
    </svg>
  ),
  大分県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 404.6 363.3">
      <defs>
        <style>
          .przm1{'{'}fill:#e8a21c;{'}'}.przm2{'{'}fill:#c26655;{'}'}.przm3{'{'}
          fill:#c9c21e;{'}'}.przm4{'{'}fill:#d94550;{'}'}.przm5{'{'}
          fill:#d6a671;{'}'}.przm6{'{'}fill:#53822b;{'}'}
        </style>
      </defs>
      <path
        className="przm3"
        d="M340.88,171.95l-191.52,157.85c-6.99,5.76-3.33,17.1,5.7,17.7l238.87,15.78c6.85,.45,12.11-5.98,10.31-12.61l-47.35-173.63c-1.91-7-10.41-9.7-16.01-5.09Z"
      />
      <path
        className="przm4"
        d="M79.54,54.85C35.26,54.02-.8,90.08,.01,134.36c.77,41.73,34.82,75.79,76.55,76.57,44.29,.83,80.35-35.24,79.53-79.52-.77-41.73-34.83-75.79-76.56-76.56Z"
      />
      <path
        className="przm2"
        d="M156.75,43.67l15.43,120.53c.81,6.35,7.29,10.31,13.31,8.14l117.63-42.43c7.97-2.88,8.98-13.74,1.67-18.03L171.73,33.78c-7.16-4.21-16.04,1.65-14.98,9.89Z"
      />
      <path
        className="przm5"
        d="M224.45,32.09L278.52,1.31c5.46-3.11,12.39-.47,14.41,5.47l26.62,78.19c2.99,8.78-6.54,16.54-14.53,11.84l-80.69-47.42c-6.63-3.9-6.56-13.51,.12-17.31Z"
      />
      <path
        className="przm1"
        d="M122.83,206.75l158.67-47c9.96-2.95,17.26,9.36,9.89,16.68l-129.53,128.76c-5.2,5.17-14.01,3.17-16.47-3.74l-29.13-81.76c-1.93-5.4,1.08-11.32,6.58-12.94Z"
      />
      <path
        className="przm6"
        d="M19.57,210.01l-14.37,12.83c-5.37,4.79-4.07,13.52,2.47,16.54l33.36,15.4c9.18,4.24,18.13-6.27,12.49-14.66l-18.99-28.22c-3.39-5.03-10.43-5.92-14.96-1.88Z"
      />
    </svg>
  ),
  大阪府: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 298.3 466.24">
      <defs>
        <style>
          .przm1{'{'}fill:#9f68e8;{'}'}.przm2{'{'}fill:#c26655;{'}'}.przm3{'{'}
          fill:#d94550;{'}'}.przm4{'{'}fill:#53822b;{'}'}.przm5{'{'}
          fill:#51b1c9;{'}'}
        </style>
      </defs>
      <path
        className="przm4"
        d="M129.09,2.52l165.78,144.84c6.09,5.32,3.67,15.31-4.19,17.24l-113.43,27.92c-5.16,1.27-10.42-1.72-11.96-6.81L112.94,12.95c-2.91-9.62,8.58-17.04,16.15-10.43Z"
      />
      <circle className="przm3" cx="203.95" cy="346.6" r="72.53" />
      <path
        className="przm5"
        d="M121.61,367.74l29.94,50.73c3.42,5.79,.26,13.24-6.28,14.81L8.82,466.02c-7.29,1.75-11.99-7.49-6.28-12.35l103.97-88.47c4.73-4.02,11.94-2.81,15.09,2.53Z"
      />
      <path
        className="przm1"
        d="M167.01,265.84h64.33c10.09,0,13.83-13.25,5.23-18.52l-60.87-37.35c-6.32-3.88-14.5,.22-15.19,7.6l-3.46,37.35c-.54,5.86,4.07,10.92,9.96,10.92Z"
      />
      <path
        className="przm2"
        d="M206.7,214.22l47.29,27.12c5.78,3.32,13.13,.15,14.7-6.33l9.74-40.34c1.75-7.23-4.73-13.77-11.98-12.09l-57.03,13.21c-8.85,2.05-10.6,13.89-2.72,18.42Z"
      />
    </svg>
  ),
  奈良県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 295.72 464.59">
      <defs>
        <style>
          .przm1{'{'}fill:#ed9489;{'}'}.przm2{'{'}fill:#8fb505;{'}'}.przm3{'{'}
          fill:#c26655;{'}'}.przm4{'{'}fill:#d94550;{'}'}.przm5{'{'}
          fill:#53822b;{'}'}
        </style>
      </defs>
      <circle className="przm4" cx="262.63" cy="141.35" r="33.09" />
      <path
        className="przm3"
        d="M72.95,9.47l-13.04,238.35c-.35,6.48,7.5,9.94,12.05,5.31L221.54,100.76c4.45-4.54,3.6-12.02-1.76-15.44L88.31,1.58c-6.45-4.11-14.94,.25-15.36,7.89Z"
      />
      <path
        className="przm2"
        d="M175.66,25.61l42.93-8.23c5.98-1.15,11.59,3.28,11.87,9.36l1.76,38.23c.38,8.2-8.74,13.34-15.56,8.76l-44.7-29.99c-7.42-4.98-5.08-16.44,3.69-18.12Z"
      />
      <path
        className="przm2"
        d="M59.67,300.71L2.97,356.69c-2.88,2.85-3.76,7.15-2.23,10.91l30.86,75.36c3.76,9.18,17.15,7.87,19.07-1.86l25.84-131.34c1.9-9.66-9.83-15.96-16.84-9.05Z"
      />
      <path
        className="przm2"
        d="M136.86,464.52l-48.79-5.49c-9.75-1.1-12.25-14.11-3.61-18.75l109.18-58.55c9.99-5.36,19.78,7.42,12,15.67l-60.38,64.04c-2.16,2.29-5.27,3.43-8.39,3.08Z"
      />
      <path
        className="przm1"
        d="M97.55,255.45l130.07-61.21c5.16-2.43,7.25-8.68,4.58-13.72l-18.75-35.5c-3.14-5.95-11.15-7.16-15.91-2.4l-105.29,105.29c-3.71,3.71,.56,9.77,5.3,7.54Z"
      />
      <path
        className="przm5"
        d="M246.74,230.3v104.36c0,3.73-2.08,7.15-5.39,8.87l-158.81,82.58c-5.34,2.78-10.68-3.7-6.95-8.42L228.9,224.09c5.88-7.43,17.84-3.27,17.84,6.21Z"
      />
      <path
        className="przm5"
        d="M193.08,246.86l-106.02,132.52c-3.67,4.59-11.05,1.29-10.07-4.51l16.84-99.36c.56-3.29,2.72-6.09,5.77-7.45l81.58-36.57c9.57-4.29,18.45,7.18,11.9,15.37Z"
      />
    </svg>
  ),
  宮城県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 390.21 491.47">
      <defs>
        <style>
          .przm1{'{'}fill:#d94550;{'}'}.przm2{'{'}fill:#53822b;{'}'}.przm3{'{'}
          fill:#76b2b4;{'}'}.przm4{'{'}fill:#51b1c9;{'}'}
        </style>
      </defs>
      <path
        className="przm2"
        d="M90.21,10.01V210.65c0,8.13,9.19,12.86,15.81,8.14L306.67,75.47c6.81-4.87,4.99-15.45-3.07-17.75L102.96,.39c-6.39-1.83-12.75,2.97-12.75,9.62Z"
      />
      <path
        className="przm3"
        d="M372.69,16.78L193.34,250.78c-6.12,7.99,2.06,19,11.48,15.44l178.14-67.39c4.29-1.23,7.25-5.15,7.25-9.62V23.36c0-9.23-11.45-13.53-17.53-6.59Z"
      />
      <path
        className="przm4"
        d="M144.78,210.69l99.04-75.07c9.32-7.06,21.11,4.87,13.94,14.1l-78.86,101.57c-4.03,5.19-11.87,5.15-15.85-.07l-20.19-26.5c-3.35-4.4-2.5-10.69,1.91-14.03Z"
      />
      <path
        className="przm2"
        d="M202.22,275.6L5.04,388.28c-6.64,3.79-6.74,13.33-.18,17.26l140.85,84.51c5.64,3.38,12.95,.5,14.76-5.83l56.34-197.18c2.46-8.62-6.79-15.88-14.58-11.43Z"
      />
      <circle className="przm1" cx="115.13" cy="271.83" r="41.75" />
    </svg>
  ),
  宮崎県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 334.64 467.47">
      <defs>
        <style>
          .przm1{'{'}fill:#c26655;{'}'}.przm2{'{'}fill:#c9c21e;{'}'}.przm3{'{'}
          fill:#d94550;{'}'}.przm4{'{'}fill:#53822b;{'}'}.przm5{'{'}
          fill:#51b1c9;{'}'}
        </style>
      </defs>
      <path
        className="przm3"
        d="M201.8,.18c-53.56-3.39-97.71,40.76-94.34,94.32,2.79,44.17,38.62,80.01,82.79,82.81,53.56,3.39,97.72-40.77,94.34-94.33C281.81,38.82,245.97,2.98,201.8,.18Z"
      />
      <path
        className="przm4"
        d="M274.09,25.67l16.65,35.23c3.44,7.27,13.62,7.72,17.68,.77l24.84-42.55c4.29-7.36-1.99-16.37-10.37-14.89l-41.49,7.32c-6.5,1.15-10.12,8.16-7.3,14.12Z"
      />
      <path
        className="przm2"
        d="M106.64,221.26L8.37,237.64c-7.83,1.3-11.09,10.77-5.73,16.62l114.65,124.99c6.55,7.14,18.42,1.72,17.3-7.91l-16.38-141.37c-.66-5.68-5.94-9.65-11.58-8.71Z"
      />
      <path
        className="przm5"
        d="M174.66,465.36l-24.97-19.35c-3.96-3.07-5.04-8.59-2.53-12.92l57.05-98.46c3.61-6.22,13.15-2.56,11.67,4.47l-25.31,120.41c-1.52,7.23-10.08,10.37-15.91,5.85Z"
      />
      <path
        className="przm2"
        d="M137.72,186.14h107.13c6.7,0,11.51,6.47,9.57,12.89l-24.68,81.89c-2.18,7.23-11.27,9.53-16.62,4.21l-82.45-81.89c-6.33-6.29-1.88-17.1,7.05-17.1Z"
      />
      <path
        className="przm1"
        d="M141.81,232.78l59.6,64.89c2.99,3.26,3.49,8.1,1.22,11.9l-36.6,61.17c-4.89,8.16-17.33,5.54-18.51-3.9l-16.03-129.29c-.71-5.76,6.39-9.05,10.32-4.77Z"
      />
    </svg>
  ),
  富山県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 398.14 352.46">
      <defs>
        <style>
          .przm1{'{'}fill:#ed9489;{'}'}.przm2{'{'}fill:#d94550;{'}'}.przm3{'{'}
          fill:#d6a671;{'}'}.przm4{'{'}fill:#6dce97;{'}'}.przm5{'{'}
          fill:#51b1c9;{'}'}
        </style>
      </defs>
      <path
        className="przm4"
        d="M164.89,138.99l143.69,161.24c4.47,5.02,12.52,4.28,16.01-1.46l72.09-118.75c3.61-5.94,.2-13.66-6.62-15l-215.78-42.5c-9.4-1.85-15.78,9.31-9.4,16.46Z"
      />
      <circle className="przm2" cx="321.45" cy="69.47" r="69.47" />
      <path
        className="przm1"
        d="M37.7,42.83L7.13,231.91c-.89,5.48,2.87,10.64,8.35,11.48l228.71,35.1c9.24,1.42,15.2-9.5,9.01-16.51L55.06,37.8c-5.59-6.32-16.02-3.31-17.36,5.03Z"
      />
      <path
        className="przm3"
        d="M40.61,257.02l91.7,14.68c7.81,1.25,11.14,10.63,5.88,16.53l-54.33,60.89c-4.72,5.29-13.29,4.13-16.43-2.23l-37.36-75.57c-3.6-7.28,2.52-15.59,10.54-14.31Z"
      />
      <path
        className="przm5"
        d="M63.32,32.14l12.56,13c5.27,5.45,14.44,3.29,16.73-3.94l5.2-16.46c2.28-7.24-4-14.28-11.45-12.83l-17.76,3.47c-7.78,1.52-10.79,11.06-5.27,16.77Z"
      />
      <path
        className="przm3"
        d="M6.92,268.47L.05,332.72c-.71,6.64,6.9,10.88,12.17,6.79l24.31-18.88c3.85-2.99,4.99-8.33,2.68-12.63l-22.28-41.48c-2.48-4.62-9.45-3.26-10.01,1.96Z"
      />
    </svg>
  ),
  山口県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 387.13 246.14">
      <defs>
        <style>
          .przm1{'{'}fill:#eb5c4b;{'}'}.przm2{'{'}fill:#8fb505;{'}'}.przm3{'{'}
          fill:#d94550;{'}'}.przm4{'{'}fill:#d6a671;{'}'}.przm5{'{'}
          fill:#51b1c9;{'}'}
        </style>
      </defs>
      <path
        className="przm3"
        d="M96.25,140.42c-23.53-1.19-42.86,18.13-41.67,41.67,1.02,20.15,17.39,36.52,37.54,37.55,23.53,1.2,42.87-18.14,41.68-41.67-1.02-20.15-17.39-36.52-37.54-37.54Z"
      />
      <path
        className="przm1"
        d="M180.68,3.18L85.34,110.96c-5.67,6.41-.55,16.45,7.97,15.62l125.79-20.17c6.15-.99,9.91-7.29,7.86-13.17L196.37,5.64c-2.76-6.3-11.13-7.61-15.69-2.46Z"
      />
      <path
        className="przm2"
        d="M283.41,71.01l-131.38,112.78c-3.17,2.72-1.23,7.92,2.95,7.9l173.27-1.1c7.29,.69,12.82-6.42,10.36-13.32l-37.27-104.37c-2.82-7.9-13.52-9.02-17.93-1.89Z"
      />
      <path
        className="przm4"
        d="M254.6,205.61c-3.28-2.16-1.81-7.25,2.11-7.34l76.72-1.69c3.51,.42,5.6,4.14,4.14,7.36l-15.13,39.13c-1.35,2.98-5.08,4-7.76,2.12l-60.08-39.58Z"
      />
      <path
        className="przm1"
        d="M350.98,238.06l.49-11.28c.14-3.21,3.21-5.47,6.32-4.64l26.46,7.06c3.89,1.04,3.82,6.59-.1,7.52l-26.98,6.45c-3.25,.78-6.34-1.77-6.19-5.11Z"
      />
      <path
        className="przm5"
        d="M72.26,84.5l-63.19,6.61c-5.08,.53-8.82,5.02-8.41,10.12L0,216.19c-.06,10.53,14.11,13.99,18.9,4.61l62.55-122.31c3.75-6.67-1.58-14.79-9.19-13.99Z"
      />
      <path
        className="przm2"
        d="M140.08,128.89l72.13-14.04c2.35-.46,3.79,2.49,1.98,4.06l-58.88,51.22c-6.01,4.98-15.16,2.14-17.3-5.36l-6.55-22.99c-1.7-6.03,2.39-12.16,8.62-12.89Z"
      />
    </svg>
  ),
  山形県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 274.33 456.7">
      <defs>
        <style>
          .przm1{'{'}fill:#ed9489;{'}'}.przm2{'{'}fill:#c26655;{'}'}.przm3{'{'}
          fill:#d94550;{'}'}.przm4{'{'}fill:#51b1c9;{'}'}
        </style>
      </defs>
      <circle className="przm3" cx="78.64" cy="364.86" r="67.26" />
      <path
        className="przm2"
        d="M172.75,384.39l-47.75,53.43c-5.61,6.28-1.42,16.26,6.99,16.65l48.16,2.23c5.73,.27,10.5-4.33,10.46-10.06l-.41-55.66c-.07-9.15-11.36-13.41-17.46-6.59Z"
      />
      <path
        className="przm4"
        d="M5.33,189.51l15.99,8.45c6.22,3.28,13.79-.7,14.6-7.68l6.14-52.62c.68-5.79-6.78-8.7-10.21-3.99L1.92,174.78c-3.59,4.92-1.97,11.88,3.41,14.73Z"
      />
      <path
        className="przm4"
        d="M79.06,7.55l-20.23,79.81c-1.36,5.35,1.87,10.78,7.22,12.15l179.86,46.01c7.79,1.99,12.87-7.98,6.67-13.1L95.12,2.3c-5.66-4.68-14.26-1.87-16.06,5.25Z"
      />
      <path
        className="przm2"
        d="M129.75,18.18l130.06,106.36c4.77,3.9,11.57-1.23,9.13-6.89l-30.73-71.16c-1.12-2.59-3.28-4.59-5.95-5.5L135.41,7.99c-6.25-2.13-10.77,6.01-5.66,10.19Z"
      />
      <path
        className="przm4"
        d="M70.56,261.96l-25.37-16.91c-3.11-2.07-4.8-5.7-4.39-9.41l9.96-90.63c.76-6.92,10.63-7.42,12.1-.62l23.03,107.15c1.89,8.78-7.85,15.4-15.32,10.42Z"
      />
      <path
        className="przm1"
        d="M242.3,155.09L79.8,112.32c-7.28-1.92-13.99,4.6-12.29,11.94l37.28,160.31c1.45,6.24,9.08,8.61,13.81,4.29l127.89-116.72c5.85-5.34,3.47-15.04-4.2-17.06Z"
      />
      <path
        className="przm2"
        d="M266.27,188.39l7.62,24.77c1.4,4.56-.61,9.48-4.82,11.75l-109.95,59.2c-5.58,3.01-10.9-4.37-6.29-8.72l97.02-91.34c5.39-5.08,14.24-2.74,16.41,4.34Z"
      />
      <path
        className="przm2"
        d="M241.71,250.79l-100.09,52.81c-4.51,2.38-4.4,8.87,.19,11.1l68.05,33.03c5.2,2.52,11.45,.12,13.63-5.23l32.15-79.09c3.51-8.64-5.68-16.96-13.93-12.61Z"
      />
      <path
        className="przm2"
        d="M168.77,337.73l13.72,6.06c6.61,2.92,8.04,11.68,2.69,16.55l-12.29,11.17c-6.14,5.58-16,1.69-16.69-6.57l-1.44-17.24c-.63-7.56,7.06-13.04,14.01-9.98Z"
      />
    </svg>
  ),
  山梨県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 388.86 397.94">
      <defs>
        <style>
          .przm1{'{'}fill:#ed9489;{'}'}.przm2{'{'}fill:#eb5c4b;{'}'}.przm3{'{'}
          fill:#d94550;{'}'}.przm4{'{'}fill:#53822b;{'}'}.przm5{'{'}
          fill:#51b1c9;{'}'}
        </style>
      </defs>
      <circle className="przm3" cx="92.73" cy="142.02" r="92.73" />
      <path
        className="przm4"
        d="M57.71,40.49h92.39c6.2,0,8.12-8.4,2.53-11.09L93.63,.99c-3.88-1.87-8.52-1.04-11.52,2.05l-28.01,28.9c-3.1,3.2-.83,8.54,3.62,8.54Z"
      />
      <path
        className="przm2"
        d="M41.06,235.52l101.57,25.15c8.8,2.18,10.39,13.99,2.49,18.43l-106.89,59.97c-6.91,3.88-15.37-1.44-14.87-9.34l5.32-85.12c.39-6.23,6.33-10.58,12.38-9.08Z"
      />
      <path
        className="przm2"
        d="M73.02,330.4l49.7-28.64c7.18-4.14,15.98,1.75,14.91,9.96l-10.11,77.5c-1.14,8.71-12.15,11.83-17.69,5l-39.59-48.86c-3.87-4.78-2.55-11.89,2.78-14.96Z"
      />
      <path
        className="przm1"
        d="M233.21,31.75l151.62,112.73c5.68,4.22,5.3,12.84-.73,16.54l-193.32,118.93c-7.36,4.53-16.61-1.78-15.08-10.29L217.4,38.01c1.32-7.31,9.85-10.68,15.81-6.25Z"
      />
      <path
        className="przm5"
        d="M214.01,281.16l150.76-99.13c4.78-3.14,10.56,2.25,7.76,7.24l-53.81,95.6c-1.69,3-4.8,4.92-8.24,5.08l-92.18,4.39c-7.31,.35-10.4-9.17-4.29-13.18Z"
      />
    </svg>
  ),
  岐阜県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 382.47 408.62">
      <defs>
        <style>
          .przm1{'{'}fill:#c26655;{'}'}.przm2{'{'}fill:#d94550;{'}'}.przm3{'{'}
          fill:#53822b;{'}'}.przm4{'{'}fill:#76b2b4;{'}'}
        </style>
      </defs>
      <circle className="przm2" cx="288.37" cy="94.1" r="94.1" />
      <path
        className="przm4"
        d="M180.06,39.48l4.43,150.44c.14,4.61-5.5,6.93-8.65,3.56l-54.53-58.29c-1.53-1.41-1.9-3.67-.91-5.5l50.28-92.46c2.44-4.49,9.24-2.86,9.39,2.24Z"
      />
      <path
        className="przm3"
        d="M9.82,211.83l298.84-5.37c10.18-.18,14.09,13.19,5.41,18.52L49.49,387.49c-5.87,3.6-13.54,.34-15-6.39L.23,223.96c-1.34-6.16,3.28-12.01,9.59-12.13Z"
      />
      <path
        className="przm1"
        d="M322.75,234.35l-130.24,81.29c-6.95,4.34-6.02,14.74,1.59,17.77l136.99,54.57c6.72,2.51,13.82-2.66,13.49-9.83l-6.29-135.95c-.36-7.77-9.06-12.17-15.54-7.86Z"
      />
      <path
        className="przm1"
        d="M85.02,377.48l32.25-18.14c8.69-4.89,18.4,4.56,13.75,13.38l-16.12,30.57c-2.89,5.49-10.04,7.04-14.95,3.25l-16.12-12.43c-5.68-4.37-5.04-13.12,1.2-16.64Z"
      />
    </svg>
  ),
  岡山県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 398.66 387.91">
      <defs>
        <style>
          .przm1{'{'}fill:#c9c21e;{'}'}.przm2{'{'}fill:#d94550;{'}'}.przm3{'{'}
          fill:#6dce97;{'}'}
        </style>
      </defs>
      <path
        className="przm2"
        d="M212.7,336.74c-12.45,1.03-22.37,10.95-23.4,23.4-1.3,15.72,11.96,28.98,27.68,27.68,12.45-1.03,22.38-10.95,23.4-23.4,1.3-15.72-11.96-28.98-27.68-27.68Z"
      />
      <path
        className="przm3"
        d="M109.18,13.49l-35.5,340.22c-.49,4.69,6.11,6.22,7.73,1.79L193.74,49.32c1.77-4.84-.41-10.23-5.06-12.46L123.45,5.51c-6.24-3-13.56,1.09-14.28,7.98Z"
      />
      <path
        className="przm3"
        d="M271.95,5.19l31.64,57.6c2.34,4.26-1.66,9.21-6.31,7.82l-82.7-24.63c-4.14-1.23-5.16-6.63-1.76-9.29L257.03,2.12c4.86-3.8,11.96-2.34,14.92,3.06Z"
      />
      <path
        className="przm3"
        d="M386.57,59.73l-53.57,11.28c-5.76,1.21-9.23,7.11-7.5,12.73l17.62,57.09c2.63,8.52,14.26,9.6,18.41,1.7l35.95-68.37c3.94-7.49-2.63-16.18-10.91-14.44Z"
      />
      <path
        className="przm3"
        d="M73.5,62.84L4.14,112.98c-3.3,2.39-4.83,6.54-3.85,10.5l53.36,216.4c1.07,4.35,7.4,3.92,7.86-.54L89.3,71.98c.89-8.6-8.8-14.2-15.8-9.14Z"
      />
      <path
        className="przm1"
        d="M112.5,369.89l251.44-104.51c2.4-1,3.64-3.67,2.84-6.15l-53.81-167.63c-1.25-3.9-6.4-4.72-8.81-1.41L106.53,362.34c-2.97,4.09,1.3,9.49,5.96,7.56Z"
      />
      <path
        className="przm1"
        d="M107.43,343.15L281.06,94.27c3.81-5.47,1.31-13.06-5-15.2l-61.06-20.63c-5.11-1.73-10.67,.91-12.57,5.97L99.58,339.02c-1.84,4.92,4.84,8.44,7.85,4.13Z"
      />
    </svg>
  ),
  岩手県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 287.61 477.95">
      <defs>
        <style>
          .przm1{'{'}fill:#d94550;{'}'}.przm2{'{'}fill:#6dce97;{'}'}.przm3{'{'}
          fill:#51b1c9;{'}'}
        </style>
      </defs>
      <circle
        className="przm1"
        cx="198.06"
        cy="337.66"
        r="73.35"
        transform="translate(-180.75 238.95) rotate(-45)"
      />
      <path
        className="przm2"
        d="M215.62,.57L70.06,56.89c-7.58,2.93-7,13.86,.86,15.96l194.09,51.99c6.84,1.83,12.74-5.1,9.85-11.56L226.33,4.97c-1.83-4.08-6.53-6.02-10.71-4.4Z"
      />
      <path
        className="przm2"
        d="M23.94,225.92L.44,295.71c-.51,1.5-.58,3.12-.2,4.66l15.49,64.06c1.22,5.04,8.58,4.38,8.89-.8l7.92-136.05c.3-5.14-6.94-6.54-8.59-1.66Z"
      />
      <path
        className="przm2"
        d="M136.48,456.56l-97.11-32.22c-3.55-1.18-5.89-4.56-5.75-8.29l10.03-268.05c.25-6.75,9.74-7.99,11.72-1.53l91.8,299.65c2,6.53-4.2,12.59-10.68,10.44Z"
      />
      <path
        className="przm2"
        d="M125.33,288.35l144.81-139.28c4.75-4.57,2.64-12.57-3.75-14.2L60.71,82.29c-6.39-1.63-12.09,4.4-10.09,10.69l60.88,191.85c1.88,5.94,9.35,7.84,13.84,3.52Z"
      />
      <path
        className="przm3"
        d="M268.58,162.13l-72.26,74.23c-4.25,4.36-2.59,11.65,3.12,13.75l76.85,28.25c5.64,2.07,11.57-2.27,11.3-8.27l-4.6-102.47c-.33-7.36-9.29-10.76-14.42-5.49Z"
      />
      <path
        className="przm3"
        d="M164.42,412.37l-12.49-3.33c-4.06-1.08-7.69,2.8-6.32,6.78l12.64,36.86c1.9,5.54,10.02,4.59,10.58-1.24l2.78-28.72c.46-4.75-2.58-9.13-7.19-10.36Z"
      />
      <path
        className="przm2"
        d="M135.07,463.92l-20.46-6.94c-3.22-1.09-5.99,2.52-4.1,5.35l7.98,11.9c2.91,4.35,9.05,5,12.82,1.37l5.24-5.06c2.14-2.06,1.33-5.66-1.48-6.61Z"
      />
    </svg>
  ),
  島根県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 399.94 371.23">
      <defs>
        <style>
          .przm1{'{'}fill:#8fb505;{'}'}.przm2{'{'}fill:#d94550;{'}'}.przm3{'{'}
          fill:#d6a671;{'}'}.przm4{'{'}fill:#53822b;{'}'}
        </style>
      </defs>
      <path
        className="przm4"
        d="M28.33,240.85L234.35,86.26c6.31-4.74,15.37-.64,15.97,7.23l8.62,112.05c.39,5.05-3.06,9.59-8.03,10.58l-214.64,42.55c-10.43,2.07-16.45-11.42-7.95-17.81Z"
      />
      <path
        className="przm4"
        d="M350.45,57.14l-38.55,75.39c-3.42,6.69,1.49,14.63,9.01,14.55l55.4-.57c6.37-.07,11.05-5.99,9.65-12.2l-16.85-74.82c-2.06-9.13-14.4-10.68-18.66-2.35Z"
      />
      <path
        className="przm1"
        d="M388.08,35.49l-5.88,1.11c-5.7,1.07-9.3,6.76-7.82,12.37l8.28,31.46c1.25,4.74,8,4.66,9.14-.1l7.85-32.68c1.69-7.05-4.45-13.5-11.57-12.16Z"
      />
      <path
        className="przm2"
        d="M55.07,264.61C24.24,263.63-.95,288.82,.03,319.65c.89,27.92,23.63,50.66,51.55,51.56,30.83,.99,56.03-24.21,55.05-55.04-.89-27.92-23.63-50.66-51.55-51.55Z"
      />
      <path
        className="przm3"
        d="M251.39,29.34L373.62,.11c4.12-.99,6.17,4.79,2.35,6.63l-114.83,55.12c-5.8,2.78-12.67-.55-14.08-6.82l-3.1-13.78c-1.2-5.34,2.11-10.65,7.43-11.92Z"
      />
      <path
        className="przm4"
        d="M265.32,71.65l60.44-24.18c8.73-3.49,17.01,5.89,12.47,14.12l-51.8,93.82c-4.68,8.48-17.49,6.01-18.68-3.6l-8.63-69.65c-.56-4.51,1.99-8.83,6.21-10.52Z"
      />
    </svg>
  ),
  広島県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 394.9 308.18">
      <defs>
        <style>
          .przm1{'{'}fill:#ed9489;{'}'}.przm2{'{'}fill:#9f68e8;{'}'}.przm3{'{'}
          fill:#8fb505;{'}'}.przm4{'{'}fill:#d94550;{'}'}.przm5{'{'}
          fill:#6dce97;{'}'}.przm6{'{'}fill:#53822b;{'}'}.przm7{'{'}
          fill:#51b1c9;{'}'}
        </style>
      </defs>
      <path
        className="przm4"
        d="M274.33,0c-50.58-.43-91.67,40.65-91.25,91.23,.41,49.11,40.54,89.25,89.66,89.67,50.58,.44,91.68-40.65,91.25-91.24C363.58,40.56,323.44,.42,274.33,0Z"
      />
      <path
        className="przm1"
        d="M361.64,139.55l-78.99,101.99c-5.81,7.5,1.1,18.11,10.31,15.83l94.35-23.34c5.17-1.28,8.43-6.39,7.41-11.62l-15.35-78.64c-1.65-8.43-12.46-11-17.72-4.21Z"
      />
      <path
        className="przm3"
        d="M14.26,204.17l89.27,41.79c7.41,3.47,7.75,13.87,.58,17.82l-65.53,36.09c-5.56,3.06-12.54,.23-14.39-5.84L.45,216.14c-2.53-8.29,5.96-15.65,13.8-11.97Z"
      />
      <path
        className="przm7"
        d="M150.21,302.41l-16.09-34.41c-3.15-6.75,1.91-14.45,9.35-14.23l75.53,2.23c10.22,.3,13.45,13.92,4.46,18.79l-59.44,32.18c-5.06,2.74-11.38,.65-13.82-4.56Z"
      />
      <path
        className="przm5"
        d="M45.56,108.51l-25.93,58.08c-3.04,6.8,4.49,13.47,10.87,9.64l121.61-72.97c5.15-3.09,2.51-11.02-3.47-10.41l-94.98,9.79c-3.56,.37-6.65,2.61-8.11,5.87Z"
      />
      <path
        className="przm2"
        d="M150.32,119.67L35.9,186.92c-7.05,4.14-6.42,14.54,1.09,17.79l80.19,34.8c5.38,2.33,11.6-.44,13.46-5.99l34.23-102.04c2.94-8.77-6.57-16.49-14.55-11.8Z"
      />
      <path
        className="przm7"
        d="M170.34,246.39l85.26,2.42c3.22,.09,6.28-1.37,8.24-3.93l32.67-42.84c3.23-4.24-.99-10.1-6.03-8.39l-121.83,41.48c-6.11,2.08-4.75,11.09,1.7,11.27Z"
      />
      <path
        className="przm6"
        d="M170.27,153.28l-23.2,61.52c-2.94,7.81,4.41,15.58,12.37,13.06l75.14-23.7c7.83-2.47,9.51-12.79,2.88-17.62l-51.94-37.82c-5.34-3.89-12.91-1.62-15.24,4.56Z"
      />
    </svg>
  ),
  徳島県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 359.76 303.89">
      <defs>
        <style>
          .przm1{'{'}fill:#8fb505;{'}'}.przm2{'{'}fill:#d94550;{'}'}.przm3{'{'}
          fill:#53822b;{'}'}
        </style>
      </defs>
      <path
        className="przm2"
        d="M70.65,52.74C30.81,50.72-1.93,83.44,.09,123.29c1.73,34.12,29.44,61.84,63.56,63.57,39.85,2.02,72.58-30.71,70.57-70.56-1.73-34.12-29.45-61.84-63.57-63.57Z"
      />
      <path
        className="przm1"
        d="M336.37,9.22l10.76,135.83c.6,7.58-7.14,13.03-14.08,9.91L149.29,72.16c-8.41-3.79-7.64-15.97,1.18-18.68L323.47,.44c6.12-1.87,12.39,2.39,12.9,8.77Z"
      />
      <path
        className="przm1"
        d="M212.39,302.09l143.07-99.95c7.96-5.56,4.12-18.07-5.59-18.2l-201.21-2.73c-7.72-.1-12.64,8.21-8.84,14.93l58.14,102.67c2.91,5.13,9.6,6.65,14.43,3.27Z"
      />
      <path
        className="przm3"
        d="M144,98.54l-3.12,59.23c-.29,5.6,4.07,10.35,9.68,10.52l143.39,4.45c10.92,.34,14.4-14.58,4.44-19.1l-140.27-63.68c-6.41-2.91-13.75,1.54-14.12,8.58Z"
      />
    </svg>
  ),
  愛媛県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 405.45 343.4">
      <defs>
        <style>
          .przm1{'{'}fill:#eb5c4b;{'}'}.przm2{'{'}fill:#9f68e8;{'}'}.przm3{'{'}
          fill:#d94550;{'}'}
        </style>
      </defs>
      <path
        className="przm3"
        d="M135.6,134.79c-29.52-1.87-53.87,22.47-52,51.99,1.54,24.35,21.29,44.11,45.64,45.65,29.53,1.87,53.87-22.47,52.01-52-1.54-24.35-21.29-44.1-45.64-45.64Z"
      />
      <path
        className="przm1"
        d="M185.02,210.6l-18.24,50.82c-1.78,4.96,4.52,8.8,8.11,4.94l33.49-35.98c3.2-3.44,3.58-8.63,.91-12.5l-6.62-9.59c-4.63-6.7-14.89-5.36-17.64,2.31Z"
      />
      <path
        className="przm1"
        d="M136.64,283.81l8.29,47.85c1.61,9.3-9.37,15.45-16.46,9.21l-29.39-25.87c-4.27-3.76-4.55-10.32-.61-14.43l21.1-21.98c5.68-5.91,15.67-2.86,17.07,5.22Z"
      />
      <path
        className="przm1"
        d="M127.19,241.76l22.48-6.33c7.85-2.21,14.9,5.39,12.1,13.05l-6.76,18.55c-2.32,6.37-10.18,8.63-15.53,4.47l-15.71-12.22c-6.47-5.04-4.47-15.3,3.43-17.52Z"
      />
      <path
        className="przm1"
        d="M75.8,174.72v4.99c0,1.59-.94,3.03-2.4,3.67L1.78,214.65c-1.45,.63-2.51-1.44-1.15-2.25l69.13-41.13c2.67-1.59,6.05,.34,6.05,3.44Z"
      />
      <path
        className="przm1"
        d="M224.15,7.51l11.45,165.09c.33,4.79-4.9,7.95-8.98,5.41l-79.17-49.12c-4.61-2.86-6.1-8.86-3.36-13.54L209.16,4c4-6.85,14.45-4.4,15,3.51Z"
      />
      <path
        className="przm1"
        d="M236.56,16.79l8.02,119.57c.54,8.01,11.43,9.96,14.72,2.64l28.36-63.23c1.45-3.23,1.08-6.98-.96-9.86L248.9,12.41c-3.97-5.61-12.8-2.47-12.34,4.38Z"
      />
      <path
        className="przm2"
        d="M309.48,72.55L387.68,14.71c6.37-4.71,15.43-.5,15.93,7.41l1.83,28.9c.31,4.86-2.93,9.24-7.67,10.36l-84.19,19.98c-5.25,1.25-8.43-5.6-4.09-8.81Z"
      />
      <path
        className="przm2"
        d="M294.81,34.86l48.97-6.89c4.08-.57,6.19,4.71,2.84,7.1l-39.16,27.97c-4.91,3.51-11.78,1.9-14.63-3.42l-5.43-10.14c-3.28-6.13,.54-13.66,7.42-14.62Z"
      />
    </svg>
  ),
  愛知県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 395.23 342.25">
      <defs>
        <style>
          .przm1{'{'}fill:#c26655;{'}'}.przm2{'{'}fill:#c9c21e;{'}'}.przm3{'{'}
          fill:#d94550;{'}'}.przm4{'{'}fill:#53822b;{'}'}.przm5{'{'}
          fill:#51b1c9;{'}'}
        </style>
      </defs>
      <path
        className="przm2"
        d="M202.62,271.74l-68.16,52.57c-8.39,6.47-2.27,19.84,8.11,17.71l108.7-22.28c8.45-1.73,10.89-12.64,3.98-17.81l-40.54-30.29c-3.59-2.69-8.54-2.65-12.09,.09Z"
      />
      <path
        className="przm5"
        d="M70.3,266.41l20.65-65.19c2.13-6.72,10.36-9.19,15.84-4.74l6.83,5.55c4.04,3.29,4.89,9.13,1.94,13.42l-37.57,54.72c-2.81,4.1-9.19,.99-7.69-3.75Z"
      />
      <circle className="przm3" cx="80.22" cy="80.22" r="80.22" />
      <path
        className="przm4"
        d="M387.55,112.85l-97.48-23.36c-6.32-1.52-12.39,3.31-12.33,9.81l1.34,150.35c.09,9.54,12.21,13.55,17.97,5.95l96.14-126.98c4.35-5.75,1.37-14.08-5.64-15.76Z"
      />
      <path
        className="przm5"
        d="M136.61,259.43l42.1,1.4c5.76,.19,8.19-7.26,3.42-10.5l-45.06-30.62c-3.86-2.63-9.1,.03-9.27,4.7l-.86,24.68c-.19,5.53,4.14,10.16,9.66,10.34Z"
      />
      <path
        className="przm1"
        d="M104.63,187.2l151.05,101.2c6.79,4.55,15.88-.53,15.56-8.7l-8.18-207.6c-.32-8.04-9.51-12.43-15.97-7.63L104.22,170.88c-5.54,4.12-5.33,12.49,.41,16.33Z"
      />
      <path
        className="przm1"
        d="M182.9,60.32l14.56,6.07c6.86,2.86,8.32,11.94,2.69,16.8l-15.17,13.11c-6.59,5.7-16.81,.82-16.53-7.88l.61-19.18c.22-7,7.38-11.61,13.84-8.91Z"
      />
    </svg>
  ),
  新潟県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 410.68 437.28">
      <defs>
        <style>
          .przm1{'{'}fill:#ed9489;{'}'}.przm2{'{'}fill:#eb5c4b;{'}'}.przm3{'{'}
          fill:#c9c21e;{'}'}.przm4{'{'}fill:#d94550;{'}'}.przm5{'{'}
          fill:#6dce97;{'}'}
        </style>
      </defs>
      <path
        className="przm1"
        d="M128.67,81.84l-22.88,51.7c-1.92,4.33,2.7,8.65,6.9,6.45l36.6-19.22c2.3-1.21,3.29-3.98,2.28-6.37l-13.73-32.49c-1.7-4.03-7.41-4.08-9.18-.08Z"
      />
      <path
        className="przm1"
        d="M137.97,138.92l-31.02,14.38c-1.8,.83-2.93,2.65-2.9,4.62l.38,21.56c.08,4.6,5.82,6.66,8.8,3.16l30.64-35.94c3.48-4.08-1.04-10.04-5.91-7.78Z"
      />
      <path
        className="przm2"
        d="M333.75,4.99l-82.09,141.88c-5.18,8.96,4.99,18.93,13.84,13.56l140.36-85.13c5.59-3.39,6.48-11.15,1.79-15.71L349.38,2.84c-4.67-4.55-12.37-3.48-15.63,2.16Z"
      />
      <path
        className="przm3"
        d="M336.78,256.39l-137.51-2.61c-9.53-.18-13.43-12.32-5.78-18.02l155.81-116.07c7.17-5.35,17.22,.7,15.86,9.54l-18.3,118.68c-.76,4.95-5.07,8.57-10.07,8.47Z"
      />
      <path
        className="przm5"
        d="M283.64,266.7l-83.48-5.22c-8.94-.56-14.05,10.02-8.05,16.68l82.53,91.54c6.1,6.76,17.33,2.52,17.43-6.59l.95-86.33c.06-5.32-4.06-9.76-9.38-10.09Z"
      />
      <circle className="przm4" cx="196.85" cy="374.62" r="51.85" />
      <path
        className="przm5"
        d="M11.91,370.46l133.56-78.23c8.3-4.86,18.01,3.65,14.27,12.52l-44.05,104.42c-1.97,4.68-7.15,7.14-12.02,5.71l-89.51-26.19c-8.42-2.46-9.81-13.79-2.25-18.23Z"
      />
      <path
        className="przm5"
        d="M11.77,398.38l27.06,4.8c8.4,1.49,11.19,12.13,4.62,17.56l-17.27,14.25c-4.92,4.06-12.34,2.53-15.26-3.14l-9.79-19.05c-3.78-7.36,2.5-15.86,10.64-14.42Z"
      />
    </svg>
  ),
  東京都: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360.28 177.43">
      <defs>
        <style>
          .przm1{'{'}fill:#9f68e8;{'}'}.przm2{'{'}fill:#8fb505;{'}'}.przm3{'{'}
          fill:#c26655;{'}'}.przm4{'{'}fill:#d94550;{'}'}
        </style>
      </defs>
      <path
        className="przm1"
        d="M246.49,129.48l55.66-4.43c5.31-.42,10.02,3.39,10.71,8.68l2.92,22.39c1.03,7.91-7.17,13.79-14.33,10.27l-56.69-27.88c-4.34-2.13-3.1-8.63,1.72-9.02Z"
      />
      <path
        className="przm1"
        d="M276.36,62.91l4.7,44.37c.59,5.55-3.48,10.5-9.03,11.01l-134.3,12.34c-6.04,.55-8.41-7.62-3.02-10.39l127.14-65.17c6.25-3.2,13.77,.86,14.51,7.85Z"
      />
      <circle className="przm4" cx="323.08" cy="79.57" r="37.2" />
      <path
        className="przm2"
        d="M2.04,16.02L83.06,124.34c2.92,3.91,8.24,5.13,12.58,2.91l99.31-51.03c7.94-4.08,6.96-15.72-1.54-18.43L13.07,.5C3.81-2.44-3.79,8.23,2.04,16.02Z"
      />
      <path
        className="przm3"
        d="M172.11,134.84l24.4,.86c5.86,.21,10.29,5.4,9.57,11.22l-3.32,26.91c-.43,3.49-4.77,4.85-7.11,2.22l-28.1-31.48c-3.43-3.84-.58-9.92,4.57-9.74Z"
      />
    </svg>
  ),
  栃木県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 382.57 485.45">
      <defs>
        <style>
          .przm1{'{'}fill:#eb5c4b;{'}'}.przm2{'{'}fill:#ffb554;{'}'}.przm3{'{'}
          fill:#8fb505;{'}'}.przm4{'{'}fill:#d94550;{'}'}.przm5{'{'}
          fill:#6dce97;{'}'}
        </style>
      </defs>
      <circle className="przm4" cx="297.83" cy="300.67" r="84.73" />
      <path
        className="przm2"
        d="M31.58,152.37l330.17,62.72c8.77,1.67,15.16-8.11,10.12-15.47L238.14,4.35c-3.11-4.53-9.29-5.71-13.84-2.64L27.85,134.25c-7.4,4.99-5.05,16.45,3.73,18.11Z"
      />
      <path
        className="przm5"
        d="M271.88,28.25l89.69,131.6c5.57,8.17,18.36,4.16,18.26-5.73l-.97-103.33c-.04-4.32-2.85-8.12-6.96-9.43l-88.71-28.27c-9.06-2.89-16.65,7.3-11.3,15.16Z"
      />
      <path
        className="przm3"
        d="M199.12,431.45l-146.24,22.73c-3.97,.62-7.92-1.2-10.04-4.62l-20.93-33.81c-4.38-7.08,1.31-16.11,9.59-15.2l167.34,18.27c7.41,.81,7.65,11.49,.29,12.64Z"
      />
      <path
        className="przm3"
        d="M231.29,414.75l-198.4-25.38c-6.93-.89-10.84-8.43-7.57-14.6l35.53-67.01c2.74-5.17,9.32-6.89,14.24-3.73l159.26,102.3c4.12,2.65,1.79,9.04-3.07,8.42Z"
      />
      <path
        className="przm3"
        d="M148.9,450.9l36.5-6.77c9.25-1.72,15.54,9.13,9.45,16.31l-18.25,21.49c-3.51,4.13-9.68,4.72-13.9,1.31l-18.25-14.72c-6.65-5.36-3.95-16.06,4.45-17.62Z"
      />
      <path
        className="przm1"
        d="M26.26,185.13L.64,253.65c-2.08,5.56,1.12,11.71,6.87,13.19l149.3,38.44c10.23,2.63,17.03-10.34,9.03-17.25L42.17,181.07c-5.27-4.56-13.47-2.46-15.91,4.06Z"
      />
      <path
        className="przm1"
        d="M184.85,289.81L49.63,174.65c-3.32-2.83-.72-8.22,3.56-7.38l177.46,34.85c6.59,1.29,10.06,8.58,6.9,14.51l-37.4,70.26c-3,5.64-10.45,7.06-15.31,2.91Z"
      />
      <path
        className="przm1"
        d="M102.36,300.76l93.05,25.48c2.21,.61,4.15,1.95,5.49,3.82l38.74,54.01c2.79,3.89-1.76,8.8-5.85,6.32l-134.79-81.78c-4.28-2.6-1.45-9.17,3.38-7.85Z"
      />
    </svg>
  ),
  沖縄県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 368.82 463.3">
      <defs>
        <style>
          .przm1{'{'}fill:#e8a21c;{'}'}.przm2{'{'}fill:#ffb554;{'}'}.przm3{'{'}
          fill:#9f68e8;{'}'}.przm4{'{'}fill:#8fb505;{'}'}.przm5{'{'}
          fill:#c26655;{'}'}.przm6{'{'}fill:#c9c21e;{'}'}.przm7{'{'}
          fill:#d94550;{'}'}.przm8{'{'}fill:#d6a671;{'}'}.przm9{'{'}
          fill:#53822b;{'}'}.przm10{'{'}fill:#395298;{'}'}.przm11{'{'}
          fill:#76b2b4;{'}'}
        </style>
      </defs>
      <circle className="przm7" cx="232.79" cy="160.59" r="33.21" />
      <path
        className="przm9"
        d="M324.26,4.44l-72.73,108.77c-3.3,4.93,1.98,11.07,7.35,8.57l104.16-48.61c5.06-2.36,7.2-8.4,4.77-13.43l-26.24-54.1c-3.37-6.95-13.02-7.62-17.31-1.19Z"
      />
      <path
        className="przm9"
        d="M332.78,97.31l-63.05,27.25c-4.85,2.1-3.86,9.25,1.37,9.95l39.29,5.28c3.75,.5,7.47-1.15,9.6-4.28l20.04-29.43c3.34-4.9-1.8-11.12-7.24-8.77Z"
      />
      <path
        className="przm11"
        d="M185.76,119.43l-32.06,32.71c-5.58,5.69-15.25,2.89-16.92-4.9l-7.85-36.64c-1.42-6.62,4.02-12.71,10.76-12.05l39.91,3.93c8.36,.82,12.04,10.95,6.16,16.95Z"
      />
      <path
        className="przm3"
        d="M56.08,255.57l58.08,22.28c5.68,2.18,8.12,8.89,5.15,14.2l-33.73,60.54c-3.16,5.67-11.54,4.94-13.67-1.19l-28.87-83.21c-2.79-8.03,5.09-15.66,13.03-12.61Z"
      />
      <path
        className="przm6"
        d="M12.38,391.18l63.18,15.41c8.34,2.03,10.41,12.94,3.4,17.88l-52.39,36.98c-5.89,4.16-14.11,.91-15.56-6.15L.21,402.91c-1.48-7.19,5.03-13.47,12.16-11.73Z"
      />
      <path
        className="przm5"
        d="M56.78,393.93l-29.2-8.39c-7.68-2.21-9.8-12.09-3.7-17.25l24.29-20.53c5.91-5,15.02-1.67,16.31,5.96l4.92,28.91c1.23,7.26-5.54,13.32-12.62,11.29Z"
      />
      <path
        className="przm2"
        d="M151.24,253.45l-32.05-20.89c-7.69-5.01-5.13-16.87,3.94-18.26l67.22-10.34c9.85-1.52,15.61,10.74,8.16,17.36l-35.16,31.23c-3.37,2.99-8.32,3.36-12.1,.9Z"
      />
      <path
        className="przm4"
        d="M87.05,259.64l11.7,5.28c5.35,2.41,11.62-.28,13.55-5.82l5.04-14.41c1.88-5.39-3.49-10.46-8.76-8.28l-21.3,8.83c-6.35,2.63-6.49,11.58-.22,14.41Z"
      />
      <path
        className="przm2"
        d="M199.58,185.44l-6.59-9.45c-3.7-5.3-11.38-5.76-15.67-.93l-13.83,15.53c-4.04,4.53-.26,11.64,5.76,10.83l24.76-3.33c6-.81,9.03-7.68,5.56-12.65Z"
      />
      <path
        className="przm10"
        d="M181.45,135.21l-10.77,11.63c-3.86,4.17-1.83,10.95,3.69,12.3l8.73,2.14c7.03,1.73,13.51-4.36,12.23-11.49l-2-11.11c-1.01-5.61-8-7.65-11.87-3.47Z"
      />
      <path
        className="przm1"
        d="M122.06,304.67l-4.12,8.44c-1.9,3.9-.25,8.6,3.67,10.46l21.9,10.35c3.35,1.58,6.79-2,5.07-5.28l-12.62-24.16c-2.96-5.66-11.1-5.55-13.91,.19Z"
      />
      <path
        className="przm6"
        d="M165.88,310.19l3.55,3.63c1.67,1.71,4.46,1.58,5.96-.28l9.49-11.69c1.86-2.3-.6-5.55-3.32-4.38l-14.42,6.24c-2.57,1.11-3.23,4.46-1.27,6.47Z"
      />
      <path
        className="przm8"
        d="M104.65,88.58l-6.05-9.29c-1-1.54-2.92-2.2-4.66-1.6l-15.77,5.46c-3.9,1.35-3.45,7.01,.62,7.72l21.82,3.84c3.45,.61,5.95-3.19,4.04-6.12Z"
      />
    </svg>
  ),
  滋賀県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 285.98 445.7">
      <defs>
        <style>
          .przm1{'{'}fill:#f2efe2;{'}'}.przm2{'{'}fill:#c9c21e;{'}'}.przm3{'{'}
          fill:#d94550;{'}'}.przm4{'{'}fill:#d6a671;{'}'}.przm5{'{'}
          fill:#6dce97;{'}'}.przm6{'{'}fill:#53822b;{'}'}.przm7{'{'}
          fill:#51b1c9;{'}'}
        </style>
      </defs>
      <path
        className="przm3"
        d="M197.25,233.47c-53.04-1.7-96.38,41.64-94.7,94.68,1.53,48.03,40.65,87.16,88.68,88.69,53.04,1.7,96.39-41.65,94.7-94.69-1.53-48.03-40.65-87.15-88.68-88.69Z"
      />
      <path
        className="przm5"
        d="M163.14,11.01l91.49,186.62c2.25,4.6,9.13,3.28,9.53-1.83l9.63-124.45c.28-3.67-1.47-7.21-4.58-9.19L174.08,1.24c-6.48-4.15-14.33,2.85-10.94,9.76Z"
      />
      <path
        className="przm6"
        d="M150.85,54.68L4.45,152.54c-7.09,4.74-5.38,15.61,2.83,17.93l234.55,66.48c8.49,2.41,15.71-6.57,11.54-14.35L165.22,58.27c-2.79-5.2-9.46-6.87-14.37-3.59Z"
      />
      <path
        className="przm4"
        d="M9.75,199.14l23.68,174.09c1.33,9.76,14.47,11.92,18.86,3.11L125.41,229.4c2.79-5.62-.21-12.39-6.25-14.08l-96.79-27.16c-6.93-1.94-13.58,3.85-12.61,10.98Z"
      />
      <path
        className="przm2"
        d="M76.43,350.56l-26.33,53.23c-2.4,4.86-.5,10.75,4.3,13.28l52.11,27.45c7.92,4.17,16.91-3.36,14.19-11.89l-25.77-80.68c-2.74-8.56-14.5-9.45-18.49-1.39Z"
      />
      <path
        className="przm1"
        d="M142.12,106.81l65.93,47.39c4.91,3.53,5.59,10.58,1.44,14.98L53.6,334.38c-3.54,3.75-9.67-.06-7.87-4.9L126.91,111.44c2.3-6.18,9.85-8.48,15.21-4.63Z"
      />
      <path
        className="przm7"
        d="M132.45,125.24c2.11-5.67,7.86-7.42,12.77-3.89l48.07,34.55c4.91,3.53,5.53,10.02,1.38,14.42l-122.95,130.29c-4.15,4.4-5.82,3.36-3.71-2.31L132.45,125.24Z"
      />
    </svg>
  ),
  熊本県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 410.73 365.96">
      <defs>
        <style>
          .przm1{'{'}fill:#ed9489;{'}'}.przm2{'{'}fill:#eb5c4b;{'}'}.przm3{'{'}
          fill:#d94550;{'}'}.przm4{'{'}fill:#51b1c9;{'}'}
        </style>
      </defs>
      <path
        className="przm3"
        d="M260.6,204.62c-45.77-.85-83.04,36.42-82.2,82.18,.8,43.13,35.99,78.33,79.12,79.14,45.77,.85,83.05-36.42,82.2-82.19-.8-43.13-35.99-78.33-79.12-79.13Z"
      />
      <path
        className="przm1"
        d="M142.1,71.44L218.33,10.67c4.99-3.98,12.38-2.33,15.2,3.4l82.01,166.52c4.52,9.18-6.02,18.42-14.53,12.73L142.78,87.57c-5.63-3.76-5.97-11.91-.68-16.13Z"
      />
      <path
        className="przm4"
        d="M167.06,173.03l36.72-27.17c3.5-2.59,8.27-2.62,11.8-.07l44.79,32.31c8.13,5.87,3.53,18.72-6.48,18.09l-81.51-5.14c-9.31-.59-12.82-12.47-5.32-18.02Z"
      />
      <path
        className="przm1"
        d="M318.71,10.84l12.84,156.96c.75,9.14,12.37,12.54,17.92,5.24l59.22-77.76c2.92-3.84,2.69-9.22-.56-12.79L336.07,3.3c-6.42-7.06-18.14-1.97-17.36,7.55Z"
      />
      <path
        className="przm4"
        d="M118.52,341.31l35.82,19.8c6.51,3.6,14.52-.91,14.83-8.34l2.83-68.35c.4-9.67-11.79-14.21-17.81-6.64l-38.65,48.55c-3.85,4.84-2.43,11.99,2.99,14.98Z"
      />
      <path
        className="przm4"
        d="M23.72,218.07L.58,284.48c-2.74,7.86,4.77,15.44,12.66,12.76l54.84-18.62c6.21-2.11,8.75-9.53,5.12-15l-31.7-47.8c-4.57-6.89-15.06-5.57-17.78,2.24Z"
      />
      <path
        className="przm4"
        d="M75.03,221.93l35.49-1.76c9.18-.45,14.06,10.67,7.51,17.12l-21.43,21.08c-4.36,4.29-11.51,3.7-15.1-1.25l-14.06-19.33c-4.67-6.43-.35-15.48,7.59-15.87Z"
      />
      <path
        className="przm4"
        d="M50.37,293.95l-27.5,9.9c-6.45,2.32-8.7,10.3-4.42,15.66l17.6,22c5.01,6.26,14.98,4.38,17.36-3.28l9.9-31.9c2.45-7.88-5.17-15.17-12.94-12.37Z"
      />
      <path
        className="przm2"
        d="M272.86,32.56l33.23,14.36c3.37,1.46,5.67,4.65,6,8.3l5.33,60.71c.96,10.93-13.84,15.21-18.86,5.44l-38.56-75.07c-4.29-8.35,4.25-17.47,12.86-13.75Z"
      />
    </svg>
  ),
  石川県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265.31 454.15">
      <defs>
        <style>
          .przm1{'{'}fill:#8fb505;{'}'}.przm2{'{'}fill:#c26655;{'}'}.przm3{'{'}
          fill:#d94550;{'}'}.przm4{'{'}fill:#53822b;{'}'}
        </style>
      </defs>
      <circle className="przm3" cx="144.19" cy="109.93" r="26.21" />
      <path
        className="przm4"
        d="M250.84,1.08l-107.73,52.93c-7.49,3.68-7.44,14.36,.07,17.98l51.69,24.91c4.37,2.11,9.62,.77,12.46-3.17L263.36,15.9c6.08-8.45-3.18-19.41-12.53-14.82Z"
      />
      <path
        className="przm1"
        d="M145.96,143.48l52.17,6.92c9.2,1.22,11.92,13.22,4.14,18.29l-47.38,30.88c-6.18,4.03-14.45,.2-15.38-7.12l-4.79-37.8c-.82-6.51,4.73-12.03,11.24-11.17Z"
      />
      <path
        className="przm2"
        d="M6.42,404.35l127.89,49.13c6.34,2.44,13.21-2.02,13.57-8.8l12.16-228.69c.37-6.88-8.27-10.22-12.63-4.89L2.26,388.68c-4.31,5.27-2.2,13.22,4.16,15.66Z"
      />
      <path
        className="przm1"
        d="M187.67,115.95l11.74-2.83c2.49-.6,4.89,1.26,4.94,3.82l.06,3.3c.04,2.48-2.16,4.4-4.61,4.02l-11.85-1.84c-3.57-.55-3.78-5.62-.27-6.47Z"
      />
    </svg>
  ),
  神奈川県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 420 351.74">
      <defs>
        <style>
          .przm1{'{'}fill:#9f68e8;{'}'}.przm2{'{'}fill:#c26655;{'}'}.przm3{'{'}
          fill:#c9c21e;{'}'}.przm4{'{'}fill:#d94550;{'}'}.przm5{'{'}
          fill:#53822b;{'}'}.przm6{'{'}fill:#51b1c9;{'}'}
        </style>
      </defs>
      <path
        className="przm3"
        d="M92.64,6.64L7.14,260c-2.4,7.1,3.78,14.14,11.13,12.69l351.36-69.38c8.46-1.67,10.75-12.69,3.66-17.59L107.44,1.74c-5.34-3.69-12.72-1.24-14.8,4.9Z"
      />
      <path
        className="przm1"
        d="M303.86,34.24l38.99,102.65c2.52,6.63,11.04,8.44,16.04,3.41l58.27-58.67c4.8-4.83,3.3-12.96-2.9-15.76l-97.26-43.98c-7.93-3.59-16.23,4.22-13.14,12.35Z"
      />
      <circle className="przm4" cx="81.2" cy="310.25" r="41.49" />
      <path
        className="przm2"
        d="M282.56,59.26l-17.45,26.84c-2.87,4.42-1.72,10.32,2.61,13.33l43.39,30.19c8.14,5.66,18.56-3.02,14.45-12.05l-25.94-57.03c-3.19-7.01-12.86-7.73-17.06-1.28Z"
      />
      <path
        className="przm5"
        d="M31.4,106.69l-24.9,8.82c-7.88,2.79-8.83,13.54-1.57,17.68l18.03,10.26c5.26,2.99,11.95,.52,14-5.17l6.87-19.08c2.79-7.74-4.68-15.25-12.44-12.5Z"
      />
      <path
        className="przm6"
        d="M318.67,232.59l12.78,90.38c1.45,10.29,15.84,11.48,18.96,1.57l24.15-76.52c2.68-5.28,.09-11.71-5.51-13.64l-37.65-13.02c-7.17-2.48-14.3,3.81-12.74,11.23Z"
      />
    </svg>
  ),
  福井県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 409.2 346.86">
      <defs>
        <style>
          .przm1{'{'}fill:#8fb505;{'}'}.przm2{'{'}fill:#d94550;{'}'}.przm3{'{'}
          fill:#51b1c9;{'}'}
        </style>
      </defs>
      <path
        className="przm1"
        d="M202.44,6.16L120.88,202.04c-2.91,6.98,2.69,14.54,10.21,13.8l269.07-26.49c8.64-.85,12.17-11.55,5.72-17.37L218.37,2.58c-5.12-4.63-13.28-2.8-15.94,3.58Z"
      />
      <path
        className="przm3"
        d="M155.6,220.3l76.04-6.25c10.12-.83,14.89,12.2,6.63,18.1l-157.78,112.74c-9.35,6.68-20.75-5.11-13.75-14.23l81.74-106.49c1.72-2.24,4.3-3.65,7.11-3.88Z"
      />
      <circle className="przm2" cx="31.6" cy="313.92" r="31.6" />
    </svg>
  ),
  福岡県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 420 463.81">
      <defs>
        <style>
          .przm1{'{'}fill:#ed9489;{'}'}.przm2{'{'}fill:#9f68e8;{'}'}.przm3{'{'}
          fill:#8fb505;{'}'}.przm4{'{'}fill:#c9c21e;{'}'}.przm5{'{'}
          fill:#d94550;{'}'}
        </style>
      </defs>
      <path
        className="przm2"
        d="M260.03,3.06L56.37,214.59c-6.15,6.39-1.53,17.03,7.34,16.9l289.9-4.53c7.05-.11,11.77-7.3,9.05-13.81L276.42,6.14c-2.76-6.63-11.41-8.26-16.4-3.08Z"
      />
      <path
        className="przm2"
        d="M66.46,182.81l-37.05,35.55c-5.32,5.11-14.16,2.87-16.41-4.15L.48,175.15c-2.15-6.72,3.16-13.49,10.2-13l49.57,3.5c8.63,.61,12.45,11.16,6.2,17.15Z"
      />
      <path
        className="przm1"
        d="M138.49,308.54l-61.39,56.06c-3.25,2.96-4.17,7.71-2.27,11.67l39.15,81.86c4.22,8.82,17.29,6.94,18.85-2.72l22.24-137.91c1.5-9.33-9.6-15.33-16.58-8.96Z"
      />
      <path
        className="przm4"
        d="M268.79,325.23l-105.64,57.62c-8.53,4.65-6.05,17.52,3.6,18.67l106.56,12.64c5.99,.71,11.23-4.01,11.15-10.04l-.91-70.26c-.1-7.52-8.15-12.23-14.75-8.63Z"
      />
      <path
        className="przm5"
        d="M223.38,237.25c-29-1.47-52.83,22.35-51.36,51.35,1.26,24.83,21.43,45.01,46.26,46.27,29,1.47,52.83-22.35,51.36-51.35-1.26-24.83-21.43-45.01-46.27-46.27Z"
      />
      <path
        className="przm3"
        d="M420,172.34v41.99c0,8.79-10.55,13.29-16.89,7.2l-31.43-30.19c-5.29-5.09-3.47-13.96,3.4-16.54l31.43-11.8c6.52-2.45,13.48,2.37,13.48,9.34Z"
      />
      <path
        className="przm4"
        d="M163,334.65l-3.38,20.06c-1.37,8.13,7.15,14.31,14.45,10.5l18.27-9.53c6.62-3.45,7.24-12.68,1.14-16.99l-14.9-10.53c-6-4.24-14.38-.76-15.6,6.49Z"
      />
    </svg>
  ),
  福島県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 408.93 363.86">
      <defs>
        <style>
          .przm1{'{'}fill:#ed9489;{'}'}.przm2{'{'}fill:#f1b8b5;{'}'}.przm3{'{'}
          fill:#eb5c4b;{'}'}.przm4{'{'}fill:#8fb505;{'}'}.przm5{'{'}
          fill:#d94550;{'}'}
        </style>
      </defs>
      <path
        className="przm4"
        d="M.1,191.49l20.09,147.37c1.01,7.45,9.57,11.15,15.7,6.8l133.35-94.68c6.64-4.72,5.16-14.97-2.54-17.61L13.25,180.69c-7.04-2.42-14.16,3.43-13.16,10.81Z"
      />
      <path
        className="przm3"
        d="M121.89,61.94L55.98,166.34c-3.45,5.47-.89,12.72,5.23,14.8l242.68,82.64c9.88,3.36,17.66-8.75,10.49-16.34L137.62,60.41c-4.48-4.74-12.24-3.99-15.72,1.53Z"
      />
      <path
        className="przm1"
        d="M243.39,6.29l-35.75,89.37c-3.42,8.54,5.53,16.77,13.76,12.66l106.08-56.28c7.37-3.69,7.37-14.2,0-17.89L257.15,1.06c-5.23-2.62-11.58-.2-13.76,5.23Z"
      />
      <path
        className="przm2"
        d="M391.02,32.32L236.71,118.81c-5.52,3.09-6.81,10.47-2.68,15.26l157.33,182.23c6.09,7.06,17.67,2.67,17.57-6.65l-3.03-268.72c-.09-7.59-8.27-12.32-14.89-8.61Z"
      />
      <circle className="przm5" cx="313.25" cy="320.05" r="43.81" />
    </svg>
  ),
  秋田県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 278.32 490.94">
      <defs>
        <style>
          .przm1{'{'}fill:#c26655;{'}'}.przm2{'{'}fill:#d94550;{'}'}.przm3{'{'}
          fill:#d6a671;{'}'}
        </style>
      </defs>
      <path
        className="przm3"
        d="M51.02,7.25L.44,177.44c-2.6,8.74,6.91,16.12,14.73,11.42L262.3,40.65c8.59-4.68,6.04-17.64-3.68-18.72L61.74,.06c-4.85-.54-9.38,2.5-10.72,7.19Z"
      />
      <circle className="przm2" cx="170.29" cy="227.4" r="92.11" />
      <path
        className="przm1"
        d="M220.58,326.02l-110.8,76.18c-5.92,4.07-5.74,12.87,.34,16.7l111.96,70.49c6.69,4.21,15.39-.64,15.33-8.54l-1.16-146.67c-.06-8.02-9.06-12.7-15.66-8.16Z"
      />
      <path
        className="przm3"
        d="M89.28,303.31l78.37,31.35c7.73,3.09,8.52,13.72,1.33,17.92l-96.31,56.31c-7.47,4.37-16.58-2.16-14.84-10.64l17.94-87.65c1.25-6.11,7.72-9.59,13.51-7.28Z"
      />
      <path
        className="przm1"
        d="M182.53,103.21l80.93-44.91c7.26-4.03,15.99,2.04,14.74,10.25l-12.34,80.93c-1.04,6.79-8.45,10.54-14.54,7.35l-68.59-36.02c-7.04-3.7-7.16-13.74-.2-17.6Z"
      />
    </svg>
  ),
  群馬県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 406.36 399.41">
      <defs>
        <style>
          .przm1{'{'}fill:#d94550;{'}'}.przm2{'{'}fill:#53822b;{'}'}
        </style>
      </defs>
      <path
        className="przm2"
        d="M3.75,179.41L232.54,2.03c5.52-4.28,13.62-1.47,15.3,5.31l62.53,252.37c1.76,7.1-4.7,13.51-11.79,11.68L7.26,196.4c-7.78-2-9.86-12.07-3.51-17Z"
      />
      <path
        className="przm2"
        d="M90.41,231.94l133.56,34.04c7.53,1.92,10.09,11.32,4.57,16.79l-114.67,113.74c-5.89,5.84-15.91,2.39-16.96-5.83l-18.89-147.77c-.89-7,5.55-12.7,12.39-10.96Z"
      />
      <circle className="przm1" cx="385.19" cy="265.32" r="21.17" />
      <path
        className="przm2"
        d="M313.8,241.96l6.78,27.9c.84,3.45,3.44,6.2,6.84,7.22l18.84,4.17c9.2,2.04,15.85-8.62,9.98-15.99l-26.92-33.8c-7.54-5.38-17.71,1.5-15.53,10.5Z"
      />
    </svg>
  ),
  茨城県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384.74 404.21">
      <defs>
        <style>
          .przm1{'{'}fill:#8fb505;{'}'}.przm2{'{'}fill:#d94550;{'}'}.przm3{'{'}
          fill:#6dce97;{'}'}.przm4{'{'}fill:#53822b;{'}'}.przm5{'{'}
          fill:#51b1c9;{'}'}
        </style>
      </defs>
      <path
        className="przm4"
        d="M140.09,398.44L3.43,279.2c-5.68-4.95-4.05-14.16,2.98-16.87l142.66-54.89c6.87-4.25,15.69,.99,15.24,9.06l-9.61,173.63c-.4,7.26-8.18,11.68-14.62,8.31Z"
      />
      <path
        className="przm3"
        d="M184.06,13.92l1.46,113.71c.03,2.23,.8,4.38,2.19,6.12l100.08,124.98c7.27,9.08,21.56,.53,17.01-10.17L203.26,9.87c-4.29-10.09-19.34-6.92-19.2,4.04Z"
      />
      <circle className="przm2" cx="309.08" cy="43.88" r="43.88" />
      <path
        className="przm1"
        d="M167.07,393.61l10.95-186.96c.56-9.62,13.07-12.95,18.35-4.9l100.74,153.73c3.67,5.6,.91,13.16-5.51,15.07l-111.69,33.23c-6.65,1.98-13.24-3.25-12.83-10.17Z"
      />
      <path
        className="przm5"
        d="M195.33,163.96l188.34,231.85c3.33,4.1-1.8,9.65-6.15,6.65l-57-39.33c-1.04-.72-1.94-1.63-2.64-2.68L189.54,168.23c-2.55-3.82,2.9-7.84,5.8-4.28Z"
      />
      <path
        className="przm3"
        d="M267.55,86.29l63.3,16.27c5.21,1.34,8.42,6.55,7.28,11.81l-19.62,90.43c-2.08,9.6-15.31,10.76-19.03,1.67l-43.68-106.71c-3.12-7.61,3.78-15.52,11.74-13.47Z"
      />
    </svg>
  ),
  長崎県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 413.31 355.29">
      <defs>
        <style>
          .przm1{'{'}fill:#9f68e8;{'}'}.przm2{'{'}fill:#c26655;{'}'}.przm3{'{'}
          fill:#d94550;{'}'}.przm4{'{'}fill:#d6a671;{'}'}.przm5{'{'}
          fill:#53822b;{'}'}.przm6{'{'}fill:#51b1c9;{'}'}
        </style>
      </defs>
      <circle className="przm3" cx="282.52" cy="19.13" r="19.13" />
      <path
        className="przm6"
        d="M6.36,289.88L.13,329c-1.31,8.25,7.48,14.38,14.76,10.29l40.45-22.67c7.06-3.96,6.74-14.23-.56-17.74l-34.23-16.45c-6.03-2.9-13.16,.83-14.21,7.44Z"
      />
      <path
        className="przm2"
        d="M197.2,183.77l3.09-27.87c.57-5.12,4.94-8.97,10.09-8.9l7.1,.11c8.3,.12,12.84,9.72,7.68,16.22l-19.13,24.09c-3.1,3.91-9.39,1.32-8.83-3.64Z"
      />
      <path
        className="przm2"
        d="M241.44,143.24l-9.98,19.97c-2.92,5.84,3.2,12.08,9.1,9.27l27.48-13.11c8.69-4.15,7.07-16.98-2.38-18.84l-13.34-2.62c-4.41-.87-8.86,1.32-10.87,5.34Z"
      />
      <path
        className="przm1"
        d="M260.06,284.76l79.02,24.05c8.55,2.6,9.65,14.25,1.75,18.41l-48.48,25.53c-4.66,2.45-10.41,.88-13.17-3.6l-30.54-49.58c-4.83-7.84,2.61-17.49,11.43-14.81Z"
      />
      <path
        className="przm5"
        d="M389.26,281.1l22.99,45.97c2.87,5.74-.33,12.66-6.56,14.18l-56.18,13.78c-7.23,1.77-13.06-6.01-9.31-12.45l36.02-61.91c2.97-5.1,10.41-4.86,13.04,.41Z"
      />
      <path
        className="przm4"
        d="M346.1,292.64l-50.01-56.2c-3.51-3.95,.83-9.89,5.66-7.75l62.25,27.61c4.64,2.06,7,7.26,5.5,12.11l-6.38,20.55c-2.31,7.43-11.85,9.5-17.02,3.68Z"
      />
      <path
        className="przm2"
        d="M323.25,232.3l-40.25-20.82c-3.04-1.57-3.81-5.58-1.57-8.17l8.92-10.31c2.24-2.59,6.23-2.65,8.54-.13l29.76,32.35c3.42,3.72-.91,9.4-5.4,7.08Z"
      />
      <path
        className="przm2"
        d="M266.33,198.92l-16.81-10.46c-4.93-3.07-4.66-10.32,.47-13.02l17.72-9.31c7.6-3.99,16.36,2.81,14.38,11.16l-3.98,16.78c-1.24,5.22-7.23,7.69-11.79,4.86Z"
      />
      <path
        className="przm4"
        d="M44.76,277.81c-4.03,3.16-9.08-2.38-5.56-6.1l65.2-68.97c1.28-1.46,3.68-.41,3.49,1.52l-2.2,22.01c-.31,3.08-2.02,5.85-4.64,7.49l-56.29,44.05Z"
      />
      <path
        className="przm4"
        d="M99.88,166.88l6.36-11.45c1.25-2.25-.74-4.93-3.26-4.37l-7.64,1.7c-1.51,.34-2.51,1.77-2.31,3.3l1.27,9.76c.37,2.86,4.18,3.58,5.57,1.06Z"
      />
      <path
        className="przm5"
        d="M231.64,258.97l7.71-25.55c1.81-5.99,9.7-7.31,13.36-2.24l30.9,42.86c3.06,4.25-.95,9.97-5.99,8.54l-39.14-11.11c-5.37-1.53-8.46-7.16-6.84-12.51Z"
      />
    </svg>
  ),
  長野県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 286.91 484.03">
      <defs>
        <style>
          .przm1{'{'}fill:#eb5c4b;{'}'}.przm2{'{'}fill:#d94550;{'}'}.przm3{'{'}
          fill:#6dce97;{'}'}.przm4{'{'}fill:#53822b;{'}'}
        </style>
      </defs>
      <path
        className="przm1"
        d="M5.1,285.46L234.06,156.66c8.33-4.69,17.86,3.86,14.1,12.65l-94.61,221.01c-2.48,5.8-9.65,7.88-14.85,4.31L4.34,302.42c-6.1-4.19-5.7-13.33,.76-16.96Z"
      />
      <path
        className="przm4"
        d="M258.92,173.28l-45.1,107.27c-2.7,6.42,1.82,13.56,8.77,13.87l53.87,2.4c6.3,.28,11.29-5.27,10.33-11.5l-16.99-110.88c-1.07-5.54-8.67-6.35-10.88-1.16Z"
      />
      <path
        className="przm3"
        d="M95.09,37.51L44.62,227.75c-2.25,8.49,6.76,15.56,14.47,11.34l163.06-89.29c6.05-3.31,7.01-11.61,1.87-16.22L111.43,32.63c-5.56-4.99-14.43-2.34-16.34,4.88Z"
      />
      <path
        className="przm4"
        d="M46.62,345.88l92.61,65.25c5.58,3.93,5.67,12.18,.17,16.23l-74.37,54.72c-6.06,4.46-14.68,.88-15.81-6.55l-18.24-119.97c-1.32-8.71,8.44-14.75,15.65-9.68Z"
      />
      <circle className="przm2" cx="221.41" cy="49.66" r="49.66" />
    </svg>
  ),
  青森県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 389.92 361.15">
      <defs>
        <style>
          .przm1{'{'}fill:#f1b8b5;{'}'}.przm2{'{'}fill:#8fb505;{'}'}.przm3{'{'}
          fill:#d94550;{'}'}.przm4{'{'}fill:#d6a671;{'}'}
        </style>
      </defs>
      <path
        className="przm2"
        d="M341.02,250.91l-49.12-41.26c-3.17-2.66-4.37-7-3.02-10.91l41.26-119.86c3.58-10.41,18.91-8.23,19.44,2.77l7.86,161.12c.43,8.72-9.74,13.76-16.42,8.14Z"
      />
      <path
        className="przm1"
        d="M.79,306.44L89.69,94.06c3.37-8.06,14.73-8.22,18.33-.27l117.4,211.6c3.7,6.66-1.12,14.85-8.74,14.85l-206.66,.06c-7.14,0-11.98-7.27-9.22-13.86Z"
      />
      <path
        className="przm4"
        d="M242.78,352.75l-28.07-172.16c0-8.49,9.9-13.11,16.41-7.67l155.18,129.7c7.17,5.99,2.93,17.67-6.41,17.67l-124.16,40.36c-5.83,1.89-11.97-1.85-12.96-7.9Z"
      />
      <circle className="przm3" cx="251.38" cy="66.78" r="66.78" />
    </svg>
  ),
  静岡県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412.55 278.8">
      <defs>
        <style>
          .przm1{'{'}fill:#c9c21e;{'}'}.przm2{'{'}fill:#d94550;{'}'}.przm3{'{'}
          fill:#53822b;{'}'}.przm4{'{'}fill:#395298;{'}'}
        </style>
      </defs>
      <path
        className="przm3"
        d="M1.83,219.64L153.3,4.27c5.62-7.99,18.18-4.01,18.18,5.75V267.99c0,6.63-6.33,11.42-12.71,9.63L7.3,235.02c-6.64-1.87-9.44-9.74-5.47-15.38Z"
      />
      <path
        className="przm4"
        d="M404.56,168.27l-67.06-13.81c-6.58-1.36-12.6,4.04-11.97,10.73l9.86,104.53c.9,9.5,13.33,12.47,18.42,4.39l57.2-90.73c3.73-5.91,.4-13.72-6.44-15.13Z"
      />
      <path
        className="przm1"
        d="M184.22,221.9V61.68c0-8.12,9.17-12.86,15.79-8.15l81.18,57.68c4.67,3.32,5.6,9.87,2.05,14.36l-81.18,102.54c-5.88,7.43-17.84,3.27-17.84-6.21Z"
      />
      <circle className="przm2" cx="349.26" cy="89.53" r="58.33" />
    </svg>
  ),
  香川県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 391.8 293.64">
      <defs>
        <style>
          .przm1{'{'}fill:#d94550;{'}'}.przm2{'{'}fill:#d6a671;{'}'}
        </style>
      </defs>
      <path
        className="przm1"
        d="M109.29,146.79c-34.35-2.17-62.66,26.14-60.5,60.49,1.79,28.33,24.77,51.31,53.09,53.1,34.35,2.17,62.67-26.14,60.5-60.49-1.79-28.32-24.77-51.31-53.1-53.1Z"
      />
      <path
        className="przm2"
        d="M146.54,115.23l35.2,118.19c2.73,9.16,15.51,9.64,18.92,.72l41.26-107.92c2.3-6.03-1.62-12.62-8.01-13.48l-76.47-10.27c-7.19-.97-12.99,5.81-10.91,12.77Z"
      />
      <path
        className="przm2"
        d="M260.68,103.55l-41.46,118.45c-1.6,4.56,2.15,9.21,6.95,8.62l159.9-19.74c5.66-.7,7.76-7.82,3.37-11.47l-118.45-98.71c-3.49-2.91-8.82-1.44-10.32,2.85Z"
      />
      <path
        className="przm2"
        d="M23.04,204.76L6.68,281.67c-.81,3.81,1.88,7.48,5.76,7.84l43.37,4.09c4.69,.44,8.28-4.08,6.79-8.55l-27-81.01c-2.1-6.3-11.17-5.79-12.55,.7Z"
      />
      <path
        className="przm2"
        d="M285.56,22.42L357.98,.29c4.44-1.36,8.83,2.23,8.39,6.85l-4.69,49.29c-.41,4.33-4.87,7.05-8.91,5.43l-67.73-27.16c-5.71-2.29-5.36-10.48,.52-12.28Z"
      />
      <path
        className="przm2"
        d="M314.77,52.58l4.69,1.56c3,1,4.24,4.57,2.5,7.21l-5.83,8.84c-1.73,2.62-5.77,1.69-6.19-1.42l-1.47-11c-.47-3.53,2.92-6.33,6.29-5.2Z"
      />
      <path
        className="przm2"
        d="M40.79,185.92l-7.93,4.46c-4.4,2.47-9.95,1.28-12.94-2.77L.72,161.64c-2.15-2.91,.91-6.79,4.24-5.38l35.01,14.85c6.32,2.68,6.8,11.45,.82,14.81Z"
      />
    </svg>
  ),
  高知県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 393.65 313.71">
      <defs>
        <style>
          .przm1{'{'}fill:#8fb505;{'}'}.przm2{'{'}fill:#d94550;{'}'}.przm3{'{'}
          fill:#53822b;{'}'}.przm4{'{'}fill:#51b1c9;{'}'}
        </style>
      </defs>
      <path
        className="przm2"
        d="M195.22,.03c-30.19-.97-54.87,23.71-53.91,53.9,.87,27.34,23.14,49.62,50.48,50.49,30.2,.97,54.88-23.71,53.91-53.91C244.83,23.17,222.56,.9,195.22,.03Z"
      />
      <path
        className="przm4"
        d="M261.94,13.87l126.43,67.73c4.41,2.36,6.4,7.62,4.65,12.31l-23.33,62.46c-2.72,7.29-12.37,8.81-17.21,2.71L249.38,28.9c-6.74-8.51,3-20.15,12.56-15.02Z"
      />
      <path
        className="przm4"
        d="M43.28,124.59l57.93,92.05c4.03,6.4,13.44,6.19,17.16-.4l50.79-89.67c3.73-6.58-.92-14.76-8.48-14.93l-108.72-2.38c-7.97-.17-12.93,8.58-8.68,15.32Z"
      />
      <path
        className="przm4"
        d="M59.44,170.35l30.79,52.48c2.51,4.28,1.5,9.77-2.38,12.87L8.27,299.37c-3.96,3.17-9.61-.79-7.99-5.59l41.06-121.56c2.74-8.12,13.76-9.25,18.1-1.86Z"
      />
      <path
        className="przm1"
        d="M63.04,267.48l-29.47,27.76c-4.69,4.42-2.34,12.3,4.01,13.43l27.56,4.9c5.38,.96,10.46-2.8,11.12-8.23l3.57-29.38c1.13-9.29-9.97-14.9-16.78-8.49Z"
      />
      <path
        className="przm3"
        d="M110.56,50.9l-22.64,35.99c-3.16,5.03,.31,11.6,6.25,11.81l36.1,1.28c7.01,.25,12.09-6.61,9.81-13.24l-11.6-33.76c-2.73-7.94-13.45-9.18-17.92-2.07Z"
      />
      <path
        className="przm1"
        d="M253.06,68.15l-3.9,9.93c-1.78,4.54,.97,9.59,5.75,10.54l11.68,2.34c7.18,1.44,12.23-6.89,7.64-12.59l-9.65-11.98c-3.25-4.03-9.63-3.05-11.52,1.76Z"
      />
    </svg>
  ),
  鳥取県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 410.2 200.59">
      <defs>
        <style>
          .przm1{'{'}fill:#9f68e8;{'}'}.przm2{'{'}fill:#8fb505;{'}'}.przm3{'{'}
          fill:#d94550;{'}'}.przm4{'{'}fill:#d6a671;{'}'}.przm5{'{'}
          fill:#53822b;{'}'}
        </style>
      </defs>
      <path
        className="przm3"
        d="M101.75,64.09c-24.81-1.26-45.2,19.12-43.94,43.94,1.07,21.25,18.34,38.51,39.58,39.59,24.82,1.26,45.2-19.12,43.95-43.94-1.08-21.25-18.34-38.51-39.59-39.59Z"
      />
      <path
        className="przm1"
        d="M66.85,66.18l-6.73,1.57c-3.09,.72-6.32-.34-8.38-2.76L24.36,32.85c-2.79-3.27,1.16-7.93,4.85-5.71l40,24.14c6.25,3.77,4.76,13.24-2.35,14.9Z"
      />
      <path
        className="przm5"
        d="M41.47,127.44l-20.96,8.54c-8.22,3.35-8.33,14.96-.17,18.45l36.22,15.52c9.03,3.87,17.65-6.25,12.38-14.55l-15.27-24.06c-2.59-4.09-7.74-5.73-12.22-3.9Z"
      />
      <path
        className="przm5"
        d="M21.81,162.76l22.92,10.21c8.57,3.82,7.64,16.27-1.41,18.77l-30.64,8.47c-8.02,2.22-15.09-5.77-11.91-13.46l7.72-18.68c2.15-5.2,8.17-7.61,13.31-5.31Z"
      />
      <path
        className="przm2"
        d="M143.98,36.84l258.33,55.96c8.66,1.88,10.78,13.26,3.37,18.13l-54.48,35.8c-3.01,1.98-6.85,2.18-10.05,.54L141.12,44.84c-4.46-2.28-2.04-9.05,2.86-7.99Z"
      />
      <path
        className="przm2"
        d="M133.32,55.75l89.61,42.92c6.14,2.94,7.61,11.02,2.9,15.94l-9.99,10.43c-3.65,3.81-9.64,4.13-13.67,.72l-73.84-62.3c-4.15-3.5,.08-10.06,4.98-7.71Z"
      />
      <path
        className="przm4"
        d="M221.36,34.49L364.86,.27c4.94-1.18,9.97,1.53,11.71,6.3l21.32,58.44c2.69,7.39-3.79,14.85-11.48,13.21L221.46,43.01c-4.55-.97-4.63-7.44-.1-8.52Z"
      />
    </svg>
  ),
  鹿児島県: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 406.91 461.61">
      <defs>
        <style>
          .przm1{'{'}fill:#c9c21e;{'}'}.przm2{'{'}fill:#d94550;{'}'}.przm3{'{'}
          fill:#d6a671;{'}'}.przm4{'{'}fill:#6dce97;{'}'}.przm5{'{'}
          fill:#76b2b4;{'}'}
        </style>
      </defs>
      <path
        className="przm3"
        d="M244.28,213.93l153.47,13.19c7.7,.66,11.77,9.44,7.3,15.75l-99.51,140.28c-4.69,6.62-14.9,5.18-17.59-2.47l-53.95-153.47c-2.41-6.85,3.06-13.9,10.29-13.28Z"
      />
      <path
        className="przm5"
        d="M239.26,282.2l40.74,124.43c1.55,4.75-.63,9.91-5.12,12.1l-85.89,41.84c-7.72,3.76-16.28-3.32-14.03-11.61l45.15-166.27c2.6-9.58,16.06-9.93,19.15-.49Z"
      />
      <path
        className="przm4"
        d="M272.08,169.75L211.41,6.52c-1.69-4.55-6.4-7.23-11.18-6.35L72.47,23.61c-8.6,1.58-11.18,12.66-4.15,17.87l188.43,139.8c7.99,5.93,18.8-2.19,15.33-11.52Z"
      />
      <path
        className="przm1"
        d="M94.22,361.79L5.26,313.89c-6.74-3.63-7.07-13.19-.59-17.27l153.98-96.95c8.38-5.27,18.5,3.53,14.45,12.56l-65.01,144.85c-2.38,5.3-8.75,7.46-13.86,4.71Z"
      />
      <circle className="przm2" cx="108.82" cy="151.51" r="58.91" />
      <path
        className="przm1"
        d="M129.2,339.7l-9.35,21.14c-2.17,4.89-.09,10.63,4.7,13l12.35,6.13c7.08,3.51,15.25-2.19,14.38-10.05l-3-27.27c-1.12-10.16-14.95-12.3-19.08-2.95Z"
      />
      <path
        className="przm3"
        d="M286.86,122.92l61.34,75.11c5.59,6.85,.2,17.06-8.62,16.29l-42.98-3.76c-4.22-.37-7.76-3.36-8.81-7.47l-18.36-71.35c-2.67-10.38,10.65-17.12,17.43-8.82Z"
      />
    </svg>
  ),
};

const Prefsvg: FC<{
  name: PrefectureStr;
}> = ({ name }) => prefsvg[name];

export default Prefsvg;
